<template>
  <div class="pkr-container">
    <span class="pkr__score">SCORE: {{ score }}</span>
    <div class="pkr-detail">
      <div class="pkr-row">
        <div class="pkr-box pkr-box--gray pkr-column-1-19"></div>
        <div
            v-for="i in 17"
            :key="i"
            class="pkr-box pkr-box--gray pkr-column-1-19"
            :class="{ 'pkr-column-2-19': i === 5 }"
        >
          <span class="pkr-detail__title">{{ i }}</span>
        </div>
      </div>
      <div class="pkr-row">
        <div class="pkr-box pkr-box--gray pkr-column-1-19"><span class="pkr-detail__title">A</span></div>
        <div
            v-for="i in 17"
            :key="i"
            class="pkr-box pkr-column-1-19"
            :class="{ 'pkr-column-2-19': i === 5 }"
        >
          {{ data[`A${i}`] }}
        </div>
      </div>
      <div class="pkr-row">
        <div class="pkr-box pkr-box--gray pkr-column-1-19"><span class="pkr-detail__title">D</span></div>
        <div class="pkr-box pkr-column-1-19">{{ data.D1 }}</div>
        <div class="pkr-box pkr-column-1-19">{{ data.D2 }}</div>
        <div class="pkr-box pkr-column-1-19">{{ data.D3 }}</div>
        <div class="pkr-box pkr-column-1-19">{{ data.D4 }}</div>
        <div
            v-for="i in 13"
            :key="i"
            class="pkr-box pkr-column-1-19"
            :class="{ 'pkr-column-2-19': i === 1 }"
        />
      </div>
      <div class="pkr-row mb-46">
        <div class="pkr-box pkr-box--gray pkr-column-1-19"><span class="pkr-detail__title">I</span></div>
        <div class="pkr-box pkr-column-1-19">{{ data.I1 }}</div>
        <div class="pkr-box pkr-column-1-19">{{ data.I2 }}</div>
        <div class="pkr-box pkr-column-1-19">{{ data.I3 }}</div>
        <div class="pkr-box pkr-column-1-19">{{ data.I4 }}</div>
        <div
            v-for="i in 13"
            :key="i"
            class="pkr-box pkr-column-1-19"
            :class="{ 'pkr-column-2-19': i === 1 }"
        />
      </div>
      <!--      <div class="pkr-row">
        <div class="pkr-box pkr-box&#45;&#45;gray pkr-column-19-19"><span class="pkr-detail__title">C1: </span>{{ data.C1 }}</div>
      </div>
      <div class="pkr-row">
        <div class="pkr-box pkr-column-4-19"><span class="pkr-detail__title">D1: </span>{{ data.D1 }}</div>
        <div class="pkr-box pkr-column-6-19"><span class="pkr-detail__title">D2: </span>{{ data.D2 }}</div>
        <div class="pkr-box pkr-column-4-19"><span class="pkr-detail__title">D3: </span>{{ data.D3 }}</div>
        <div class="pkr-box pkr-column-4-19"><span class="pkr-detail__title">D4: </span>{{ data.D4 }}</div>
      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'PkrVisualization',
  props: {
    score: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      headers: [
        {
          text: 'Поле', value: 'key', sortable: false, class: 'history__table-first-header', cellClass: 'history__table-first-cell', divider: true
        },
        {
          text: 'Значение', value: 'value', sortable: false,
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.pkr {
  &-container {
    display: flex;
    flex-direction: column;
    gap: 26px;
  }

  &__score {
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    color: #484964;
    opacity: 0.5;
  }

  &-box {
    padding: 12px 15px;
    border: 1px solid #EEEEF2;

    &--gray {
      background-color: #F9F9FC;
    }
  }

  &-detail {
    color: #484964;
    font-size: 14px;
    line-height: 18px;

    &__title {
      font-weight: 700;
    }
  }

  &-row {
    display: flex;
  }

  &-column-1-19 {
    width: percentage(1/19);
  }

  &-column-2-19 {
    width: percentage(2/19);
  }

  &-column-4-19 {
    width: percentage(4/19);
  }

  &-column-6-19 {
    width: percentage(6/19);
  }

  &-column-19-19 {
    width: 100%;
  }
}

.mb-46 {
  margin-bottom: 46px;
}
</style>
