<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <div class="statistics-container">
          <h3 class="statistics__header">Статистика запросов</h3>
          <div class="header-container">
            <v-icon class="header__icon"> mdi-filter-outline </v-icon>
            <span>Фильтры</span>
          </div>
          <div class="filter-container">
            <v-row v-if="role==='admin'">
              <v-col cols="2">
                <v-text-field
                    outlined
                    label="Номер договора"
                    :value="filter.contractNumber"
                    @input="setContractNumber"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-select
                    :items="periodsCatalog"
                    outlined
                    clearable
                    label="Период"
                    v-model="period"
                />
              </v-col>
              <v-col cols="2">
                <date-picker
                    label="Дата (начало)"
                    :value="filter.dateFrom"
                    @input="setDateFrom"
                />
              </v-col>
              <v-col cols="2">
                <date-picker
                    label="Дата (конец)"
                    :value="filter.dateTo"
                    @input="setDateTo"
                />
              </v-col>
              <v-col cols="2">
                <v-select
                    :items="statusesCatalog"
                    outlined
                    multiple
                    clearable
                    small-chips
                    label="Статусы"
                    :value="filter.statuses"
                    @input="setStatuses"
                />
              </v-col>
              <v-col cols="3">
                <v-autocomplete
                    :items="servicesCatalog"
                    outlined
                    multiple
                    small-chips
                    clearable
                    label="Сервисы"
                    no-data-text="Сервисы не найдены"
                    :value="filter.services"
                    @input="setServices"
                />
              </v-col>
            </v-row>
          </div>
          <div class="header-container">
            <v-icon class="header__icon"> mdi-clipboard-list-outline </v-icon>
            <span>Результаты</span>
          </div>
          <v-data-table
              :headers="headers"
              :items="data"
              :items-per-page="-1"
              group-by="user"
              locale="ru-RU"
              :loading="pending"
              loading-text=""
              no-data-text="Нет данных"
              hide-default-footer
              class="history__table"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  PERIODS_CATALOG,
  SERVICES_CATALOG,
  STATUSES_CATALOG,
} from '@/helpers/dictionaries'
import DatePicker from '@/components/form/DatePicker'
import {
  endOfMonth,
  endOfQuarter,
  endOfYear,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  subMonths,
} from 'date-fns'
import { formatDate } from '@/helpers/functions'

export default {
  name: 'Statistics',
  components: { DatePicker },
  data() {
    return {
      data: [],
      servicesCatalog: [],
      statusesCatalog: [],
      periodsCatalog: [],
      period: 'currentMonth',
      headers: [
        {
          text: 'Критерий',
          value: 'criteria',
          class: 'statistics__table-first-header',
          cellClass: ['statistics__table-first-cell', 'statistics__table-item'],
          divider: true,
        },
        {
          text: '# Запросов (результат)',
          value: 'summary',
          sortable: false,
          class: 'statistics__table-summary-header',
          cellClass: 'statistics__table-item',
          divider: true,
        },
        {
          text: '# Запросов (ответ найден)',
          value: 'found',
          sortable: false,
          class: 'statistics__table-found-header',
          cellClass: 'statistics__table-item',
          divider: true,
        },
        {
          text: '# Запросов (ответ не найден)',
          value: 'notFound',
          sortable: false,
          cellClass: 'statistics__table-item',
          divider: true,
        },
        {
          text: '# Запросов (ожидание обработки)',
          value: 'wait',
          sortable: false,
          cellClass: 'statistics__table-item',
          divider: true,
        },
        {
          text: '# Запросов (ошибка)',
          value: 'error',
          sortable: false,
          cellClass: 'statistics__table-item',
          divider: true,
        },
        // {
        //   text: '# Запросов (всего)',
        //   value: 'all',
        //   sortable: false,
        //   cellClass: 'statistics__table-item',
        // }
      ],
      pending: false,
    }
  },
  computed: {
    filter: {
      get() {
        return this.$store.getters['statisticsFilter/filter']
      },
      set() {},
    },
    ...mapGetters('user', ['role'])
  },
  methods: {
    async getStatisticsData() {
      this.pending = true
      let params = {
        dateFrom: this.filter.dateFrom || '',
        dateTo: this.filter.dateTo || '',
        contractNumber: this.filter.contractNumber || undefined,
        // status: this.filter.statuses.join(',') || '',
        // service: this.filter.services.join(',') || '',
      }
      await this.axios
          .get('/report', {
            params,
          })
          .then((response) => {
            this.data = this.processData(response.data)
          })
      this.pending = false
    },
    processData(data) {
      return data.map((dataEntry) => {
        return {
          criteria: `Источник: ${
            SERVICES_CATALOG.find(
              (service) => service.value === dataEntry.service
            )?.abbr ?? dataEntry.service
          }`,
          summary: dataEntry.summary,
          found: dataEntry.found,
          notFound: dataEntry.not_found,
          wait: dataEntry.wait,
          error: dataEntry.error,
          all: dataEntry.all,
          user: dataEntry.user.login,
        }
      })
    },
    setDateFrom(value) {
      this.$store.commit('statisticsFilter/setDateFrom', value || '')
      this.period = 'custom'
    },
    setDateTo(value) {
      this.$store.commit('statisticsFilter/setDateTo', value || '')
      this.period = 'custom'
    },
    setStatuses(value) {
      this.$store.commit('statisticsFilter/setStatuses', value || [])
    },
    setServices(value) {
      this.$store.commit('statisticsFilter/setServices', value || [])
    },
    setContractNumber(value) {
      this.$store.commit('statisticsFilter/setContractNumber', value || '')
    },
    setDatesByPeriod(period) {
      let date = new Date()
      switch (period) {
        case 'currentMonth':
          this.$store.commit(
            'statisticsFilter/setDateFrom',
            formatDate(startOfMonth(date).toISOString())
          )
          this.$store.commit(
            'statisticsFilter/setDateTo',
            formatDate(endOfMonth(date).toISOString())
          )
          break
        case 'lastMonth':
          date = subMonths(date, 1)
          this.$store.commit(
            'statisticsFilter/setDateFrom',
            formatDate(startOfMonth(date).toISOString())
          )
          this.$store.commit(
            'statisticsFilter/setDateTo',
            formatDate(endOfMonth(date).toISOString())
          )
          break
        case 'quarter':
          this.$store.commit(
            'statisticsFilter/setDateFrom',
            formatDate(startOfQuarter(date).toISOString())
          )
          this.$store.commit(
            'statisticsFilter/setDateTo',
            formatDate(endOfQuarter(date).toISOString())
          )
          break
        case 'year':
          this.$store.commit(
            'statisticsFilter/setDateFrom',
            formatDate(startOfYear(date).toISOString())
          )
          this.$store.commit(
            'statisticsFilter/setDateTo',
            formatDate(endOfYear(date).toISOString())
          )
          break
        default:
          break
      }
    },
  },
  watch: {
    period(period) {
      this.setDatesByPeriod(period)
    },
    filter: {
      immediate: true,
      deep: true,
      handler: function () {
        if (this.filter.dateFrom && this.filter.dateTo) this.getStatisticsData()
      },
    },
  },
  created() {
    this.servicesCatalog = SERVICES_CATALOG
    this.statusesCatalog = STATUSES_CATALOG
    this.periodsCatalog = PERIODS_CATALOG
    this.setDatesByPeriod('currentMonth')
  },
}
</script>

<style lang="scss" scoped>
.statistics {
  &-container {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 17px;
  }

  &__header {
    margin-bottom: 20px;
    color: #141414;
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
  }
}

.filter {
  &-container {
    padding: 30px 20px 0 15px;
    margin-bottom: 30px;
    background: #f9f9fc;
    border-radius: 8px;
  }
}

.header {
  &-container {
    display: flex;
    align-items: center;
    gap: 12px;

    margin-bottom: 15px;
    color: #484964;
    font-size: 14px;
    font-weight: 500;
  }

  &__icon {
    color: #484964 !important;
    font-size: 25px;
    font-weight: normal;
  }
}

::v-deep .statistics__table-first-header {
  background: rgba(100, 77, 237, 0.08);
  border-top: 1px solid #627ee0;
}

::v-deep .statistics__table-found-header {
  color: #6eaa6c !important;
}
::v-deep .statistics__table-summary-header {
  color: #567ac2 !important;
}

::v-deep .statistics__table-first-cell {
  background: rgba(100, 77, 237, 0.08);
}

::v-deep .statistics__table-item {
  cursor: pointer;
}

::v-deep .v-row-group__header {
  background: rgba(100, 77, 237, 0.08) !important;
}
</style>
