<template>
  <div>
    <v-data-table
        :items="data"
        :headers="headers"
        :items-per-page="-1"
        hide-default-footer
        class="request__table"
    >
      <template v-slot:item.type="{ item }">
        <span>{{typeAddress(item.type)}}</span>
      </template>
      <template v-slot:item.address="{ item }">
        <span>{{formatAddress(item)}}</span>
      </template>
    </v-data-table>
  
  </div>
</template>

<script>
import { region, country } from '@/helpers/dictionaries'
export default {
  name: 'GetAddressVisualization',
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          key: 'Вид',
          value: 'type',
        },
        {
          key: 'Адрес',
          value: 'address',
        },
        {
          key: 'Дата учета',
          value: 'updatedAt',
        },
      ]
    }
  },
  methods: {
    typeAddress(type){
      if(type == '1'){
        return 'Адрес регистрации'
      }
      else if(type == '2'){
        return 'Адрес фактического местожительства'
      }
      return type
    },
    formatAddress(addr){
      return [
        addr.postal, 
        addr.country ? country.find(el=>el.value === addr.country)?.text : addr.country, 
        addr.region ? region.find(reg=>reg.value === addr.region)?.text : addr.region, 
        addr.area,// ? addr.area + 'р-н' : addr.area, 
        addr.city, 
        addr.street.name, 
        addr.house ? addr.house + ' д.' : addr.house, 
        addr.block ? addr.block + ' корп.' : addr.block,  
        addr.building ? addr.building + ' стр.' : addr.building, 
        addr.flat ? addr.flat + ' кв.' : addr.flat
      ].filter(Boolean).join(', ') 
    }
  }
}
</script>

