import Vue from 'vue'
import Vuex from 'vuex'
import { user } from './user'
import { filter } from './filter'
import { statisticsFilter } from './statisticsFilter'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    user,
    filter,
    statisticsFilter,
  },
})
