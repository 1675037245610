<template>
  <v-app class="app">
    <Header v-if="!isUserRoute" />
    <Sidebar v-if="!isUserRoute" />
    <v-main :class="{ 'main': !isUserRoute }">
      <v-row no-gutters class="fill-height">
        <v-col cols="12">
          <router-view></router-view>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>
import Header from '@/components/Header'
import Sidebar from '@/components/Sidebar'

export default {
  name: 'App',
  components: { Header, Sidebar },
  computed: {
    isUserRoute () {
      return this.$route.path === '/auth' || this.$route.path === '/registration'
    }
  },
  created () {
    if (this.$store.getters['user/isLoggedIn']) {
      this.$store.commit('user/restoreAuth')
      if (this.$store.getters['user/tokenExpiration'] && Date.now() >= this.$store.getters['user/tokenExpiration'] * 1000) {
        this.$store.dispatch('user/logout')
            .then(() => this.$router.push({ name: 'Auth' }))
      }
    } else {
      this.$router.push({ name: 'Auth' })
    }
  }
}
</script>

<style lang="scss">
.app {
  background: #F8F8F8 !important;
  font-family: $body-font-family, sans-serif !important;
  .title {
    font-family: $title-font, sans-serif !important;
  }
}

.main {
  padding: 48px 0 0 200px !important;
}

.form-group {
  display: inline-block;
  padding: 0 10px;
}

.column-3 {
  width: percentage(3/12);
}

.column-4 {
  width: percentage(4/12);
}

.column-5 {
  width: percentage(5/12);
}

.column-6 {
  width: percentage(6/12);
}

.column-8 {
  width: percentage(8/12);
}

.column-12 {
  width: 100%;
}

.required label::after {
  content: "*";
  color: red;
}
</style>
