export const statisticsFilter = {
  state: {
    filter: {
      dateFrom: '',
      dateTo: '',
      contractNumber: '',
      statuses: [],
      services: [],
    },
  },
  getters: {
    filter: state => {
      return state.filter
    },
  },
  mutations: {
    resetFilter: (state) => {
      state.filter = {
        dateFrom: '',
        dateTo: '',
        contractNumber: '',
        statuses: [],
        services: [],
      }
    },
    setDateFrom: (state, payload) => {
      state.filter.dateFrom = payload
    },
    setDateTo: (state, payload) => {
      state.filter.dateTo = payload
    },
    setStatuses: (state, payload) => {
      state.filter.statuses = payload
    },
    setServices: (state, payload) => {
      state.filter.services = payload
    },
    setContractNumber: (state, payload) => {
      state.filter.contractNumber = payload
    },
  },
  namespaced: true,
}
