<template>
  <v-checkbox
      dense
      v-model="value"
      class="fact-match-checkbox"
      :class="{ required: requiredClass }"
      :hint="hint"
      persistent-hint
  >
    <template v-slot:label>
      <span class="fact-match-checkbox-label">
        {{ schema.lbl }}
      </span>
    </template>
  </v-checkbox>
  <!-- <v-text-field
    outlined
    dense
    v-model='value'
    :hint='hint'
    persistent-hint
    :rules='[...rules, ...schema.rules]'
    append-icon='mdi-pencil-outline'
    :class='{ required: requiredClass }'
  /> -->
</template>

<script>
import { abstractField } from 'vue-form-generator'

export default {
  name: 'CheckBoxField',
  mixins: [abstractField],
  data() {
    return {
      required: '',
      hint: undefined,
      rules: [],
    }
  },
  computed: {
    requiredClass() {
      return this.schema.required() || !!this.required
    },
  },

  created() {
    if (this.schema.required()) {
      this.rules = [(value) => !!value || value === 0 || 'Обязательное поле']
    }
    if (this.schema.default !== undefined) this.value = this.schema.default
    if (this.schema.hnt) this.hint = this.schema.hnt()
  },
}
</script>

<style scoped></style>
