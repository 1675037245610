<template>
  <v-container fluid id="top">
    <v-row>
      <v-col>
        <div class="history-container" :class="{ 'history-container--white': this.type === 'history' }">
          <h3 class="history__header">{{ title }}</h3>
          <div v-if="type === 'history'" class="header-container">
            <v-icon class="header__icon">
              mdi-filter-outline
            </v-icon>
            <span>Фильтры</span>
          </div>
          <div v-if="type === 'history'" class="filter-container">
            <v-row>
              <v-col cols="2">
                <date-picker label="Дата (начало)" :value="filter.dateFrom" @input="setDateFrom" />
              </v-col>
              <v-col cols="2">
                <date-picker label="Дата (конец)" :value="filter.dateTo" @input="setDateTo" />
              </v-col>
              <v-col cols="2">
                <v-select
                    :items="statusesCatalog"
                    outlined
                    multiple
                    clearable
                    small-chips
                    label="Статусы"
                    :value="filter.statuses"
                    @input="setStatuses"
                />
              </v-col>
              <v-col cols="3">
                <v-autocomplete
                    :items="servicesCatalog"
                    outlined
                    multiple
                    small-chips
                    clearable
                    label="Сервисы"
                    no-data-text="Сервисы не найдены"
                    :value="filter.services"
                    @input="setServices"
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                    outlined
                    clearable
                    label="Поиск"
                    :value="search"
                    @input="setSearch"
                    append-icon="mdi-magnify"
                />
              </v-col>
            </v-row>
          </div>
          <v-row v-else>
            <v-col cols="3">
              <v-text-field
                  outlined
                  clearable
                  dense
                  label="Поиск"
                  :value="search"
                  @input="setSearch"
                  append-icon="mdi-magnify"
              />
            </v-col>
          </v-row>
          <div v-if="type === 'history'" class="header-container">
            <v-icon class="header__icon">
              mdi-clipboard-list-outline
            </v-icon>
            <span>Запросы</span>
          </div>
          <v-data-table
              :headers="headers"
              :items="data"
              :items-per-page="-1"
              :custom-sort="customSort"
              :search="filter.search"
              sort-by="date"
              :sort-desc="true"
              locale="ru-RU"
              :loading="pending"
              loading-text=""
              no-data-text="Запросы не найдены"
              hide-default-footer
              @click:row="openRequest"
              class="history__table"
          >
            <template v-slot:item.date="{ item }">
              <span>{{ item.date.formatted }}</span>
            </template>
            <template v-slot:item.sources="{ item }">
              <v-tooltip
                  v-for="source in item.sources"
                  :key="source.title"
                  right
                  :color="
                    source.status === 'wait'
                      ? 'tertiary' : source.status === 'found'
                        ? 'success' : source.status === 'not_found'
                          ? 'primary' : 'error'
                  "
                  class="history__tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <span
                      v-bind="attrs"
                      v-on="on"
                      :class="{
                        'history__status--wait': source.status === 'wait',
                        'history__status--found': source.status === 'found',
                        'history__status--not-found': source.status === 'not_found',
                        'history__status--error': source.status === 'error',
                      }"
                      class="history__status"
                  >
                    #{{ source.abbr }}
                  </span>
                </template>
                <span>{{ source.description }}</span>
              </v-tooltip>
            </template>
            <template v-slot:item.status="{ item }">
              <span
                  :class="{
                    'history__status--found': item.status.value === 'found',
                    'history__status--partially': item.status.value === 'partially',
                    'history__status--error': item.status.value === 'error'
                  }"
              >
                {{ item.status.text }}</span>
            </template>
          </v-data-table>
        </div>
      </v-col>
    </v-row>
    <v-fade-transition>
      <v-btn
          v-if="scrollVisible"
          color="primary"
          large
          fab
          fixed
          bottom
          right
          class="stt-btn"
          @click="scrollToTop"
      >
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-fade-transition>
  </v-container>
</template>

<script>
import { format } from 'date-fns'
import { debounce } from 'lodash'
import { STATUSES_CATALOG, SERVICES_CATALOG } from '@/helpers/dictionaries'
import DatePicker from '@/components/form/DatePicker'

export default {
  name: 'RequestHistory',
  components: { DatePicker },
  props: {
    type: {
      type: String,
      default: 'history'
    },
  },
  data () {
    return {
      data: [],
      statusesCatalog: [],
      servicesCatalog: [],
      pending: false,
      cursor: {
        limit: 20,
        hasNext: false,
        endCursor: '',
      },
      headers: [
        {
          text: 'Дата / Время', value: 'date', class: 'history__table-first-header',
          cellClass: ['history__table-first-cell', 'history__table-item'], divider: true
        },
        {
          text: 'Запрос', value: 'title', sortable: false, cellClass: 'history__table-item', divider: true
        },
        {
          text: 'Источники', value: 'sources', sortable: false, cellClass: 'history__table-item', divider: true
        },
        {
          text: 'Статус запроса', value: 'status', sortable: false, cellClass: 'history__table-item',
        },
      ],
      scroll: {
        positionY: 0,
        scY: 0,
        scTimer: 0,
      },
    }
  },
  computed: {
    title () {
      return this.type === 'history' ? 'История запросов' : 'Последние запросы'
    },
    scrollVisible () {
      return this.scroll.scY > this.scroll.positionY
    },
    filter: {
      get () { return this.$store.getters['filter/filter'] },
      set () {  }
    },
    search: {
      get () { return this.$store.getters['filter/search'] },
      set () {  }
    },
  },
  methods: {
    async getRequests () {
      this.pending = true
      let params = {}
      if (this.type === 'home')
        params = {
          limit: this.cursor.limit || 12,
          endCursor: this.cursor.endCursor || '',
          query: this.search || '',
        }
      else
        params = {
          limit: this.cursor.limit || 12,
          endCursor: this.cursor.endCursor || '',
          dateFrom: this.filter.dateFrom || '',
          dateTo: this.filter.dateTo || '',
          status: this.filter.statuses.join(',') || '',
          service: this.filter.services.join(',') || '',
          query: this.search || '',
        }
      await this.axios.get('/request', {
        params,
      }).then(response => {
        this.cursor.hasNext = response.data.pageInfo.hasNextPage
        if (this.cursor.hasNext) {
          this.cursor.endCursor = response.data.pageInfo.endCursor
        } else {
          this.cursor.endCursor = ''
        }
        const batch = response.data.edges.map(edge => edge.node)
        this.processData(batch)
      })
      this.pending = false
    },
    async getFilteredRequests () {
      this.data = []
      this.cursor.hasNext = false
      this.cursor.endCursor = ''
      await this.getRequests()
    },
    processData (batch) {
      this.data.push(...batch.map(request => {
        const date = {
          raw: request.createdAt,
          formatted: format(new Date(request.createdAt), 'dd.MM.yyyy HH:mm'),
        }
        const title = this.getRequestTitle(request.params, request.id.split('-')[0])
        const sources = request.report.map(report => {
          return {
            ...SERVICES_CATALOG.find(service => service.value === report.service),
            status: report.status,
          }
        })
        const wait = request.report.find(report => report.status === 'wait')
        const found = request.report.find(report => report.status === 'found')
        const notFound = request.report.find(report => report.status === 'not_found')
        const error = request.report.find(report => report.status === 'error')
        const status = {}
        if (wait && !(found || notFound || error)) {
          status.value = 'wait'
          status.text = 'В обработке'
        } else if ((found || notFound) && !(wait || error)) {
          status.value = 'found'
          status.text = 'Выполнен'
        } else if ((found || notFound) && (wait || error)) {
          status.value = 'partially'
          status.text = 'Выполнен частично'
        } else if (error && !(wait || found || notFound)) {
          status.value = 'error'
          status.text = 'Не выполнен'
        }

        return {
          id: request.id,
          date,
          title,
          sources,
          status,
        }
      }))
    },
    getRequestTitle (params, requestNumber) {
      let title
      if (params?.person?.lastName || params?.person?.dateOfBirth || params?.document?.number) {
        const fio = `${params.person?.lastName || ''} ${params.person?.firstName || ''} ${params.person?.middleName || ''}`.trim()
        const dateOfBirth = params.person?.dateOfBirth || ''
        const number = params?.document?.number || ''
        const inn = params?.person?.inn || ''
        title = `Запрос #${requestNumber}: ${fio ? `${fio}` : ''}${dateOfBirth ? `, дата рождения ${dateOfBirth}` : ' '}${number ? `, паспорт ${number}` : ''}${inn ? `, ИНН ${inn}` : ''}`
            .replace(' ,', '')
      } else if (params?.person?.inn) {
        title = `Запрос #${requestNumber}: ИНН ${params.person.inn}`
      } else {
        title = `Запрос #${requestNumber}`
      }
      return title
    },
    openRequest (item) {
      this.$router.push({ name: 'Request', params: { id: item.id } })
    },
    customSort (items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] === 'date') {
          if (!isDesc[0]) {
            return new Date(a.date.raw) - new Date(b.date.raw)
          } else {
            return new Date(b.date.raw) - new Date(a.date.raw)
          }
        }
        else if (index[0] === 'status') {
          if (!isDesc[0]) {
            return a[index].text.toLowerCase().localeCompare(b[index].text.toLowerCase())
          }
          else {
            return b[index].text.toLowerCase().localeCompare(a[index].text.toLowerCase())
          }
        } else {
          if(typeof a[index] !== 'undefined'){
            if (!isDesc[0]) {
              return a[index].toLowerCase().localeCompare(b[index].toLowerCase())
            }
            else {
              return b[index].toLowerCase().localeCompare(a[index].toLowerCase())
            }
          }
        }
      })
      return items
    },
    setLimit () {
      const screenHeight = window.screen.height
      if (screenHeight >= 2160) {
        this.cursor.limit = 20
      } else if (screenHeight >= 1800) {
        this.cursor.limit = 18
      } else if (screenHeight >= 1360) {
        this.cursor.limit = 16
      } else {
        this.cursor.limit = 12
      }
    },
    setDateFrom (value) {
      this.$store.commit('filter/setDateFrom', value || '')
    },
    setDateTo (value) {
      this.$store.commit('filter/setDateTo', value || '')
    },
    setStatuses (value) {
      this.$store.commit('filter/setStatuses', value || [])
    },
    setServices (value) {
      this.$store.commit('filter/setServices', value || [])
    },
    setSearch (value) {
      this.$store.commit('filter/setSearch', value || '')
    },
    checkScrollY () {
      if (this.scroll.scTimer) return
      this.scroll.scTimer = setTimeout(() => {
        this.scroll.scY = window.scrollY
        clearTimeout(this.scroll.scTimer)
        this.scroll.scTimer = 0
      }, 100)
    },
    scrollToTop () {
      this.$scrollTo('#top')
    },
    checkScrollBottom () {
      let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.documentElement.offsetHeight
      if (bottomOfWindow && this.cursor.hasNext) {
        this.getRequests()
      }
    },
    async getInitialRequests () {
      await this.getRequests()
      if (document.getElementById('top').offsetHeight < window.innerHeight && this.cursor.hasNext) {
        await this.getInitialRequests()
      }
    }
  },
  watch: {
    filter: {
      deep: true,
      handler: async function () {
        await this.getFilteredRequests()
      },
    },
    search: debounce(async function (value) {
      if ((value?.length > 1 && !value.endsWith(' ')) || value === '') await this.getFilteredRequests()
    }, 500),
  },
  async created () {
    this.setLimit()
    this.statusesCatalog = STATUSES_CATALOG
    this.servicesCatalog = SERVICES_CATALOG
  },
  mounted () {
    this.getInitialRequests()
    this.scroll.positionY = document.getElementById('top').offsetTop
    this.scroll.positionY = this.type === 'home' ? this.scroll.positionY + 300 : this.scroll.positionY + 500
    window.addEventListener('scroll', this.checkScrollBottom)
    window.addEventListener('scroll', this.checkScrollY)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.checkScrollBottom)
    window.removeEventListener('scroll', this.checkScrollY)
  },
}
</script>

<style lang="scss" scoped>
.history {
  &-container {
    padding: 20px 20px 30px 20px;
    border-radius: 17px;

    &--white {
      background: #FFFFFF;
    }
  }

  &__header {
    margin-bottom: 20px;
    color: #141414;
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
  }

  &__table {
    margin: 25px 0 30px 0;
    border: 1px solid #EEEEF2 !important;
    transition: height .5s;
  }

  &__status {
    cursor: default;

    &--wait {
      color: #484964;
    }

    &--found {
      color: #6EAA6C;
    }

    &--not-found {
      color: #627EE0;
    }

    &--partially {
      color: #0A84F4;
    }

    &--error {
      color: #F27D7D;
    }
  }
}

.filter {
  &-container {
    padding: 30px 20px 0 15px;
    margin-bottom: 30px;
    background: #F9F9FC;
    border-radius: 8px;
  }
}

.header {
  &-container {
    display: flex;
    align-items: center;
    gap: 12px;

    margin-bottom: 15px;
    color: #484964;
    font-size: 14px;
    font-weight: 500;
  }

  &__icon {
    color: #484964 !important;
    font-size: 25px;
    font-weight: normal;
  }
}

.stt-btn {
  z-index: 100;
}

::v-deep .history__table-first-header {
  background: rgba(100, 77, 237, 0.08);
  border-top: 1px solid #627EE0;
}

::v-deep .history__table-first-cell {
  background: rgba(100, 77, 237, 0.08);
}

::v-deep .history__table-item {
  cursor: pointer;
}
</style>
