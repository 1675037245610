import { checkINN } from 'ru-validation-codes'

export const formConfig = (val) => {
  return {
    groups: [
      {
        fields: [
          {
            type: 'group-title',
            lbl: 'Персональная информация',
            styleClasses: 'column-12',
            visible: () =>
              val.services.some((service) =>
                [
                  'pkr',
                  'skiptrace',
                  'expertise',
                  'get_document',
                  'get_address',
                  'get_inn',
                  'fssp_fl',
                  'fssp_suspect',
                  'fssp_ip_search',
                  'mvd_wanted',
                  'fnp_zalog',
                  'fedsfm',
                  'bankrot_fl',
                ].includes(service),
              ),
            hnt: () => {
              if (
                val.services.some((service) =>
                  ['get_document'].includes(service),
                )
              ) {
                return 'Для запроса ППС необходимо указать фамилию, имя и дату рождения, или паспорт. Для уточнения условий поиска рекомендуется указывать все данные.'
              }

              if (
                val.services.some((service) =>
                  ['get_address'].includes(service),
                )
              ) {
                return 'Для запроса АДР необходимо указать фамилию, имя и дату рождения, или паспорт. Для уточнения условий поиска рекомендуется указывать все данные.'
              }

            },
          },
          {
            type: 'text',
            model: 'person.lastName',
            lbl: 'Фамилия',
            styleClasses: 'column-4',
            services: () => val.services,
            visible: () =>
              val.services.some((service) =>
                [
                  'pkr',
                  'skiptrace',
                  'expertise',
                  'get_document',
                  'get_address',
                  'get_inn',
                  'fssp_fl',
                  'fssp_suspect',
                  'fssp_ip_search',
                  'mvd_wanted',
                  'fnp_zalog',
                  'fedsfm',
                  'bankrot_fl'
                ].includes(service),
              ),
            required: () =>
              val.services.some((service) =>
                [
                  'pkr',
                  'skiptrace',
                  'expertise',
                  'get_inn',
                  'fssp_fl',
                  'fssp_suspect',
                  'fssp_ip_search',
                  'mvd_wanted',
                  'fnp_zalog',
                  'fedsfm',
                  //'bankrot_fl'
                ].includes(service),
              ),
            dynamicRequired: () =>
              val.services.some((service) =>
                ['get_document', 'get_address', 'bankrot_fl'].includes(service),
              ),
            rules: [],
          },
          {
            type: 'text',
            model: 'person.firstName',
            lbl: 'Имя',
            styleClasses: 'column-4',
            services: () => val.services,
            visible: () =>
              val.services.some((service) =>
                [
                  'pkr',
                  'skiptrace',
                  'expertise',
                  'get_document',
                  'get_address',
                  'get_inn',
                  'fssp_fl',
                  'fssp_suspect',
                  'fssp_ip_search',
                  'mvd_wanted',
                  'fnp_zalog',
                  'fedsfm',
                  'bankrot_fl'
                ].includes(service),
              ),
            required: () =>
              val.services.some((service) =>
                [
                  'pkr',
                  'skiptrace',
                  'expertise',
                  'get_inn',
                  'fssp_fl',
                  'fssp_suspect',
                  'fssp_ip_search',
                  'mvd_wanted',
                  'fnp_zalog',
                  'fedsfm',
                  //'bankrot_fl'
                ].includes(service),
              ),
            dynamicRequired: () =>
              val.services.some((service) =>
                ['get_document', 'get_address', 'bankrot_fl'].includes(service),
              ),
            rules: [],
          },
          {
            type: 'text',
            model: 'person.middleName',
            lbl: 'Отчество',
            styleClasses: 'column-4',
            visible: () =>
              val.services.some((service) =>
                [
                  'pkr',
                  'skiptrace',
                  'expertise',
                  'get_document',
                  'get_address',
                  'get_inn',
                  'fssp_fl',
                  'fssp_suspect',
                  'fssp_ip_search',
                  'mvd_wanted',
                  'fnp_zalog',
                  'fedsfm',
                  'bankrot_fl'
                ].includes(service),
              ),
            required: () => false,
            rules: [],
          },
          {
            type: 'date',
            model: 'person.dateOfBirth',
            lbl: 'Дата рождения',
            styleClasses: 'column-4',
            visible: () =>
              val.services.some((service) =>
                [
                  'pkr',
                  'skiptrace',
                  'expertise',
                  'get_document',
                  'get_address',
                  'get_inn',
                  'fssp_fl',
                  'mvd_wanted',
                  'fnp_zalog',
                  'fedsfm',
                ].includes(service),
              ),
            required: () =>
              val.services.some((service) =>
                [
                  'pkr',
                  'skiptrace',
                  'expertise',
                  'get_inn',
                  'fssp_fl',
                  'mvd_wanted',
                ].includes(service),
              ),
            dynamicRequired: () =>
              val.services.some((service) =>
                ['get_document', 'get_address'].includes(service),
              ),
            rules: [
              (value) =>
                value === '' ||
                /^\d{2}.\d{2}.\d{4}$/.test(value) ||
                'Неверный формат даты',
            ],
          },
          {
            type: 'text',
            model: 'person.placeOfBirth',
            lbl: 'Место рождения',
            styleClasses: 'column-4',
            visible: () =>
              val.services.some((service) => ['pkr'].includes(service)),
            required: () =>
              val.services.some((service) => ['pkr'].includes(service)),
            rules: [],
          },
          {
            type: 'phone',
            model: 'phone',
            lbl: 'Телефон',
            styleClasses: 'column-6',
            visible: () =>
              val.services.some((service) =>
                ['skiptrace', 'expertise'].includes(service),
              ),
            required: () => false,
            rules: [
              (value) => {
                let error = false
                const pattern = /^(8|7)?[3489]\d{9}$/
                value.forEach((val) => {
                  if (!pattern.test(val)) error = true
                })
                return !error || 'Неверный формат телефона'
              },
            ],
          },
          {
            type: 'text',
            model: 'person.inn',
            lbl: 'ИНН',
            styleClasses: 'column-4',
            services: () => val.services,
            visible: () =>
              val.services.some((service) => ['nalog_inn', 'bankrot_fl'].includes(service)),
            required: () => val.services.some((service) => ['nalog_inn'].includes(service)),
            dynamicRequired: () => val.services.some((service) => ['bankrot_fl'].includes(service)),
            rules: [
              //(value) =>  || value.length == 12 || 'Обязательное поле',
              (value) => !value || checkINN(value) || 'Не валидный ИНН',
            ],
          },
        ],
      },
      {
        fields: [
          {
            type: 'group-title',
            lbl: 'Документ',
            styleClasses: 'column-12',
            visible: () =>
              val.services.some((service) =>
                [
                  'pkr',
                  'skiptrace',
                  'expertise',
                  'get_document',
                  'get_address',
                  'get_inn',
                ].includes(service),
              ),
          },
          {
            type: 'passport',
            model: 'document.number',
            lbl: 'Паспорт',
            mask: '#### ######',
            styleClasses: 'column-4',
            cols: 4,
            visible: () =>
              val.services.some((service) =>
                [
                  'pkr',
                  'skiptrace',
                  'expertise',
                  'get_document',
                  'get_address',
                  'get_inn',
                  'fms_document',
                ].includes(service),
              ),
            required: () =>
              val.services.some((service) =>
                [
                  'pkr',
                  'skiptrace',
                  'expertise',
                  'get_inn',
                  'fms_document',
                ].includes(service),
              ),
            dynamicRequired: () =>
              val.services.some((service) =>
                ['get_document', 'get_address'].includes(service),
              ),
            rules: [
              (value) =>
                value === '' ||
                /^\d{4} \d{6}$/.test(value) ||
                'Неверный формат',
            ],
          },
          {
            type: 'date',
            model: 'document.issueDate',
            lbl: 'Дата выдачи',
            styleClasses: 'column-4',
            visible: () =>
              val.services.some((service) =>
                ['pkr', 'expertise'].includes(service),
              ),
            required: () =>
              val.services.some((service) =>
                ['pkr', 'expertise'].includes(service),
              ),
            rules: [
              (value) => !!value || 'Обязательное поле',
              (value) =>
                /^\d{2}.\d{2}.\d{4}$/.test(value) || 'Неверный формат даты',
            ],
          },
          {
            type: 'text',
            model: 'document.issueAuthority',
            lbl: 'Орган выдачи',
            styleClasses: 'column-4',
            visible: () =>
              val.services.some((service) =>
                ['pkr', 'expertise'].includes(service),
              ),
            required: () =>
              val.services.some((service) =>
                ['pkr', 'expertise'].includes(service),
              ),
            rules: [(value) => !!value || 'Обязательное поле'],
          },
          {
            type: 'text',
            model: 'document.issueCountry',
            lbl: 'Место выдачи',
            styleClasses: 'column-4',
            visible: () =>
              val.services.some((service) =>
                ['pkr', 'expertise'].includes(service),
              ),
            required: () =>
              val.services.some((service) =>
                ['pkr', 'expertise'].includes(service),
              ),
            rules: [(value) => !!value || 'Обязательное поле'],
          },
        ],
      },
      {
        fields: [
          {
            type: 'address-group',
            model: 'addressReg',
            addressType: 'reg',
            visible: () =>
              val.services.some((service) => ['expertise'].includes(service)),
          },
          {
            type: 'address-group',
            model: 'addressFact',
            addressType: 'fact',
            visible: () =>
              val.services.some((service) => ['expertise'].includes(service)) &&
              !val.request?.addressReg?.matchFact,
          },
        ],
      },

      {
        fields: [
          {
            type: 'region',
            model: 'address.region',
            lbl: 'Регион',
            visible: () => {
              return val.services.some((service) => ['bankrot_fl'].includes(service))
            },

            required: () =>
              val.services.some((service) =>
                [].includes(service),
              ),
            rules: []
          }
        ],
      },
      {
        fields: [
          {
            type: 'group-title',
            lbl: 'Опции',
            styleClasses: 'column-12',
            visible: () =>
              val.services.some((service) => ['skiptrace'].includes(service)),
          },
          {
            type: 'text',
            inputType: 'number',
            model: 'options.limit',
            lbl: 'Лимит (тел.)',
            default: 1,
            hnt: () =>
              'Укажите количество телефонов (0 — все найденные телефоны)',
            styleClasses: 'column-4',
            visible: () => {
              const visible = val.services.find(
                (service) => service === 'skiptrace',
              )
              if (!visible) delete val.request.options.limit
              return visible
            },
            required: () =>
              val.services.some((service) => ['skiptrace'].includes(service)),
            rules: [
              (value) =>
                !(value === null || value === '') || 'Обязательное поле',
            ],
          },
          {
            type: 'text',
            inputType: 'number',
            model: 'options.months',
            lbl: 'Актуальность (мес.)',
            hnt: () => 'Укажите количество месяцев (0 — за весь период)',
            styleClasses: 'column-4',
            default: 0,
            visible: () => {
              const visible = val.services.find(
                (service) => service === 'skiptrace',
              )
              if (!visible) delete val.request.options.months
              return visible
            },
            required: () =>
              val.services.some((service) => ['skiptrace'].includes(service)),
            rules: [
              (value) =>
                !(value === null || value === '') || 'Обязательное поле',
            ],
          },
        ],
      },
      {
        fields: [
          {
            type: 'group-title',
            lbl: 'Опции',
            styleClasses: 'column-12',
            visible: () =>
              val.services.some((service) => ['fssp_fl'].includes(service)),
          },
          {
            type: 'check-box',
            model: 'archive',
            lbl: 'Поиск архивной информации',
            styleClasses: 'column-12',
            visible: () =>
              val.services.some((service) =>
                [
                  'fssp_fl',
                ].includes(service),
              ),
            required: () => false,
            rules: [],
          },
        ]
      }
    ],
  }
}
