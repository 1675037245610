<template>
  <v-combobox
      outlined
      multiple
      clearable
      dense
      :label="schema.lbl"
      hint="Можно ввести несколько значений. Для ввода нажмите Enter"
      v-model="value"
      :rules="schema.rules"
      append-icon="mdi-phone-outline"
  />
</template>

<script>
import { abstractField } from 'vue-form-generator'

export default {
  name: 'PhoneField',
  mixins: [ abstractField ],
}
</script>

<style scoped>

</style>
