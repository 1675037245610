<template>
  <v-autocomplete
    outlined
    dense
    clearable
    :label="schema.lbl"
    v-model="value"
    :items="regionsCatalog"
    item-text="name"
    item-value="code"
    append-icon="mdi-pencil-outline"
    :class="{ required: requiredClass }"
    :rules="schema.rules"
  />
</template>

<script>
import { REGIONS_CATALOG } from "@/helpers/dictionaries";
import { abstractField } from "vue-form-generator";

export default {
  name: "fieldRegion",
  mixins: [abstractField],
  data() {
    return {
      regionsCatalog: [],
    };
  },
  created() {
    this.regionsCatalog = REGIONS_CATALOG;
  },
  computed: {
    requiredClass() {
      return this.schema.required() || !!this.required;
    },
  },
};
</script>

<style scoped></style>
