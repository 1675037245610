<template>
  <v-menu
      v-model="menu"
      :close-on-content-click="false"
      max-width="290"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          v-bind="attrs"
          outlined
          dense
          clearable
          v-model="dateFormatted"
          :label="schema.lbl"
          :rules="[...rules, ...schema.rules]"
          v-mask="'##.##.####'"
          :class="{ required: requiredClass }"
      >
        <template v-slot:append>
          <v-icon v-bind="attrs" v-on="on" class="date-icon">
            mdi-calendar-outline
          </v-icon>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
        dense
        v-model="date"
        locale="ru-ru"
        :first-day-of-week="1"
        @change="handleInput"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { format, parseISO } from 'date-fns'
import { abstractField } from 'vue-form-generator'

export default {
  name: 'DateField',
  mixins: [ abstractField ],
  data () {
    return {
      date: '',
      dateFormatted: '',
      menu: false,
      required: '',
      rules: [],
    }
  },
  computed: {
    requiredClass () {
      return this.schema.required() || !!this.required
    },
  },
  methods: {
    handleInput () {
      if (this.date) this.dateFormatted = format(parseISO(this.date), 'dd.MM.yyyy')
      this.menu = false
    },
    watchDocumentNumber () {
      this.required = !this.model.document?.number
      if (this.required) this.rules = [value => !!value || 'Обязательное поле']
      else this.rules = []
    },
  },
  watch: {
    dateFormatted () {
      this.value = this.dateFormatted
    },
  },
  created () {
    if (this.schema.required()) {
      this.rules = [value => !!value || 'Обязательное поле']
    } else if (this.schema.dynamicRequired()) {
      // console.log(123)
      this.watchDocumentNumber()
      this.$watch('model.document.number', this.watchDocumentNumber)
    }
  }
}
</script>

<style lang="scss" scoped>
.date-icon {
  transition: color .2s;

  &:hover {
    color: #627EE0;
  }
}
</style>
