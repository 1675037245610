import VueRouter from 'vue-router'
import { store } from '@/store/store'

import Home from '@/views/Home'
import Request from '@/views/request/Request'
import RequestHistory from '@/views/request/RequestHistory'
import Statistics from '@/views/statistics/Statistics'
import Auth from '@/views/user/Auth'
import Registration from '@/views/user/Registration'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/request/:id',
    name: 'Request',
    component: Request,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/history',
    name: 'History',
    component: RequestHistory,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: Statistics,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Auth
  },
  {
    path: '/registration',
    name: 'Registration',
    component: Registration
  },
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  if (store.getters['user/tokenExpiration'] && Date.now() >= store.getters['user/tokenExpiration'] * 1000) {
    store.dispatch('user/logout')
        .then(() => next({ name: 'Auth' }))
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters['user/isLoggedIn']) {
      next({ name: 'Auth' })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
