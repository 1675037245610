var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"history-container"},[_c('span',{staticClass:"history__header--last"},[_vm._v("Последние запросы по физическим лицам")]),_c('v-text-field',{staticClass:"history__search",attrs:{"label":"Поиск","outlined":"","dense":"","single-line":"","hide-details":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"history__table",attrs:{"headers":_vm.headers,"items":_vm.data,"custom-sort":_vm.customSort,"search":_vm.search,"sort-by":"date","sort-desc":true,"locale":"ru-RU","loading":_vm.pending,"loading-text":"","no-data-text":"Запросы не найдены","hide-default-footer":""},on:{"click:row":_vm.openRequest},scopedSlots:_vm._u([{key:"item.date",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.date.formatted))])]}},{key:"item.sources",fn:function({ item }){return _vm._l((item.sources),function(source){return _c('v-tooltip',{key:source.title,staticClass:"history__tooltip",attrs:{"right":"","color":source.status === 'wait'
                    ? 'tertiary' : source.status === 'found'
                      ? 'success' : source.status === 'not_found'
                        ? 'primary' : 'error'},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"history__status",class:{
                      'history__status--wait': source.status === 'wait',
                      'history__status--found': source.status === 'found',
                      'history__status--not-found': source.status === 'not_found',
                      'history__status--error': source.status === 'error',
                    }},'span',attrs,false),on),[_vm._v(" #"+_vm._s(source.abbr)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(source.description))])])})}},{key:"item.status",fn:function({ item }){return [_c('span',{class:{
                  'history__status--wait': item.status.value === 'wait',
                  'history__status--found': item.status.value === 'found',
                  'history__status--partially': item.status.value === 'partially',
                  'history__status--error': item.status.value === 'error'
                }},[_vm._v(" "+_vm._s(item.status.text))])]}}])})],1)])],1),_c('v-btn',{attrs:{"text":"","color":"secondary","to":"/history"}},[_c('v-icon',[_vm._v("mdi-chevron-left")]),_vm._v(" Все запросы по физическим лицам ")],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }