<template>
  <v-row>
    <v-col>
      <div class="request-container">
        <v-btn text color="secondary" to="/history">
          <v-icon>mdi-chevron-left</v-icon>
          Вернуться к истории
        </v-btn>
        <div class="request__header">
          <h3 class="request__title">
            {{ title }}
            <span class="request__title-number">#{{ requestNumber }}</span>
          </h3>
          <div class="request__actions-container">
            <v-btn
                v-if="request.report[0].service.includes('expertise')"
                color="primary"
                @click="downloadReport"
                class="request__actions-btn"
            >Скачать <v-icon>mdi-download-outline</v-icon></v-btn
            >
          </div>
        </div>
        <v-btn text plain @click="dataVisible = !dataVisible" class="expansion">
          <v-icon class="expansion__icon"> mdi-clipboard-list-outline </v-icon>
          Исходные данные
          <v-icon
              class="expansion__icon"
              :class="{ 'expansion__icon--active': dataVisible }"
          >
            mdi-chevron-down
          </v-icon>
        </v-btn>
        <v-expand-transition>
          <div v-if="dataVisible" class="request__data">
            <v-data-table
                :items="data"
                :headers="headers"
                :items-per-page="-1"
                hide-default-footer
                class="request__table"
            >
              <template v-slot:item.key="{ item }">
                <span>{{ $t(`field.${item.key}`) }}</span>
              </template>
            </v-data-table>
          </div>
        </v-expand-transition>
        <v-btn
            text
            plain
            @click="reportVisible = !reportVisible"
            class="expansion"
        >
          <v-icon class="expansion__icon"> mdi-clipboard-list-outline </v-icon>
          Результаты
          <v-icon
              class="expansion__icon"
              :class="{ 'expansion__icon--active': reportVisible }"
          >
            mdi-chevron-down
          </v-icon>
        </v-btn>
        <v-expand-transition>
          <div v-if="reportVisible" class="request__reports-container">
            <div
                v-for="(report, index) in request.report"
                :key="report.service"
                class="request__report-container"
            >
              <div class="request__report-header">
                <div class="request__report-info">
                  <span
                      class="request__report-title"
                  >#{{ index + 1 }} {{ getServiceName(report.service) }}</span
                  >
                  <span
                      class="request__report-status"
                  >Статус запроса:
                    <span
                        :class="{
                          'request__report-status--wait':
                            report.status === 'wait',
                          'request__report-status--found':
                            report.status === 'found',
                          'request__report-status--not-error':
                            report.status === 'not_found',
                          'request__report-status--error':
                            report.status === 'error',
                        }"
                    >
                      {{ getStatusName(report.status) }}
                    </span>
                  </span>
                </div>
              </div>
              <div
                  v-if="
                    report.status === 'not_found' &&
                      report.service === 'fms_document'
                  "
                  class="request__result-container"
              >
                <div class="request__result-container">
                  <span class="request__result-info__title"> Статус: </span>
                  <span>{{ report.result?.document.note }}</span>
                </div>
              </div>
              <div
                  v-if="report.status === 'found'"
                  class="request__result-container"
              >
                <iframe
                    v-if="report.result && report.result.file"
                    :src="`data:text/html;charset=utf-8;base64,${report.result.file}`"
                    class="request__result-frame"
                />
                <div v-if="report.service === 'expertise'">
                  <span class="request__result-info-header">
                    Расшифровка вектора
                  </span>
                  <div
                      v-for="vector in report.result.vector"
                      :key="vector.code"
                      class="request__result-info"
                  >
                    <span
                        :class="{
                          'request__result-info--neutral': vector.status === 0,
                          'request__result-info--positive': vector.status === 1,
                          'request__result-info--negative': vector.status === 2,
                        }"
                        class="request__result-info__title"
                    >
                      {{ vector.code }}:
                    </span>
                    <span>
                      {{ vector.message }}
                    </span>
                  </div>
                </div>
                <div
                    v-else-if="report.service === 'pkr'"
                    class="request__result-container"
                >
                  <PkrVisualization
                      :score="report.result.reasons.score"
                      :data="report.result.reasons"
                  />
                </div>
                <div
                    v-else-if="report.service === 'get_inn'"
                    class="request__result-container"
                >
                  <span class="request__result-info__title"> ИНН: </span>
                  <span>{{ report.result.inn }}</span>
                </div>
                <div
                    v-else-if="report.service === 'fms_document'"
                    class="request__result-container"
                >
                  <span class="request__result-info__title"> Статус: </span>
                  <span>{{ report.result.document.note }}</span>
                </div>
                <div
                    v-else-if="report.service === 'mvd_wanted'"
                    class="request__result-container"
                >
                  <span class="request__result-info__title"> Статус: </span>
                  <span>По переданным данным есть иформация о розыске [{{report.count}}]</span>
                </div>
                <div
                    v-else-if="report.service === 'get_address'"
                    class="request__result-container"
                >
                  <GetAddressVisualization :data="report.result" />
                </div>
                <div
                    v-else-if="report.service === 'fnp_zalog'"
                    class="request__result-container"
                >
                  <FnpZalogVisualization :data="report.result" />
                </div>
                <div v-else class="request__result-flex-container">
                  <template v-if="report.result.length">
                    <div
                        v-for="(res, index) in report.result"
                        :key="index"
                        class="request__result-flex-container__item"
                    >
                      <span>Результат #{{ index + 1 }}</span>
                      <v-data-table
                          :items="processTableData(res, report.service)"
                          :headers="headers"
                          :items-per-page="-1"
                          hide-default-footer
                          class="request__table"
                      >
                        <template v-slot:item.key="{ item }">
                          <span>{{ $t(`field.${item.key}`) }}</span>
                        </template>
                      </v-data-table>
                    </div>
                  </template>
                  <template v-else>
                    <v-data-table
                        :items="processTableData(report.result, report.service)"
                        :headers="headers"
                        :items-per-page="-1"
                        hide-default-footer
                        class="request__table"
                    >
                      <template v-slot:item.key="{ item }">
                        <span>{{ $t(`field.${item.key}`) }}</span>
                      </template>
                    </v-data-table>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </v-expand-transition>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import {
  STATUSES_CATALOG,
  SERVICES_CATALOG,
  PHONE_TYPES_CATALOG,
} from '@/helpers/dictionaries'
import PkrVisualization from '@/views/visualizations/PkrVisualization'
import GetAddressVisualization from '@/views/visualizations/GetAddressVisualization'
import FnpZalogVisualization from '@/views/visualizations/FnpZalogVisualization'

export default {
  name: 'RequestData',
  components: { PkrVisualization, GetAddressVisualization, FnpZalogVisualization },
  props: {
    request: {
      type: Object,
      required: true,
    },
    requestNumber: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      data: [],
      headers: [
        {
          text: 'Поле',
          value: 'key',
          sortable: false,
          class: 'history__table-first-header',
          cellClass: 'history__table-first-cell',
          divider: true,
        },
        {
          text: 'Значение',
          value: 'value',
          sortable: false,
        },
      ],
      dataVisible: false,
      reportVisible: true,
    }
  },
  computed: {
    title() {
      return this?.request?.params?.person
        ? 'Запрос по физическому лицу'
        : 'Запрос по транспортному средству'
    },
  },
  methods: {
    getData() {
      if (!this.request) return
      Object.entries(this.request.params).forEach((param) => {
        let key = param[0]
        let dataObject = param[1]
        let data = null
        if(key==='test') return
        if (Array.isArray(param[1])) {
          if (param[0] === 'address') {
            dataObject = dataObject.map(
              (address) =>
                `${address.city}, ${address.street}, ${address.house}, ${address.flat}`
            )
          }
          data = {
            key,
            value: dataObject.join(', '),
          }
          this.data.push(data)
        } else {
          data = Object.entries(dataObject).map((field) => {
            const key = field[0]
            let value = Array.isArray(field[1])
              ? field[1].join(', ')
              : field[1]
            return {
              key: key,
              value: value,
            }
          })
          this.data.push(...data)
        }
      })
    },
    processTableData(result, service) {
      let res = []
      if (service === 'fssp_fl') {
        res.push(
          {
            key: 'name',
            value: result.name,
          },
          {
            key: 'exe_production',
            value: result.exe_production,
          },
          {
            key: 'details',
            value: result.details,
          },
          {
            key: 'ip_end',
            value: result.ip_end,
          },
          {
            key: 'subject',
            value: result.subject,
          },
          {
            key: 'department',
            value: result.department,
          },
          {
            key: 'bailiff',
            value: result.bailiff,
          },
          {
            key: 'actualDate',
            value: result.actualDate,
          }
        )
        res = res.filter((field) => field.value)
        return res
      }
      if (service === 'fssp_ip_search') {
        res.push(
          {
            key: 'caseNumber',
            value: [result.caseNumber, result.caseDate].join(' '),
          },
          {
            key: 'ip',
            value: [result.ip, result.ipDate, result.sd, result.sdDate].join(
              ' '
            ),
          },
          {
            key: 'wantedObject',
            value: result.wantedObject,
          },
          {
            key: 'debtor',
            value: [
              result.lastName,
              result.firstName,
              result.middleName,
              result.dateOfBirth,
            ]
                .join(' ')
                .trim(),
          }
        )
        res = res.filter((field) => field.value)
        return res
      }
      if (service === 'fssp_suspect') {
        res.push(
          {
            key: 'suspect',
            value: [
              result.lastName,
              result.firstName,
              result.middleName,
              result.dateOfBirth,
            ]
                .join(' ')
                .trim(),
          },
          {
            key: 'placeOfBirth',
            value: result.placeOfBirth,
          },
          {
            key: 'criminalCode',
            value: result.criminalCode,
          }
        )
        res = res.filter((field) => field.value)
        return res
      }
      if (service === 'fedsfm') {
        res.push(
          {
            key: 'suspect',
            value: result.fullName.trim(),
          }
        )
        res = res.filter((field) => field.value)
        return res
      }
      if (service === 'bankrot_fl') {
        res.push(
          {
            key: 'fio',
            value: result.fio.trim(),
          },
          {
            key: 'inn_and_snisl',
            value: [result.inn, result.snils].filter(el=>!!el).join(', '),
          },
          {
            key: 'place_of_residence',
            value: result.address,
          },
          {
            key: 'legal.number',
            value: result.lastLegalCase.number,
          },
          {
            key: 'arbitrManagerFio',
            value: result.arbitrManagerFio,
          }
        )
        res = res.filter((field) => field.value)
        return res
      }
      Object.entries(result).forEach((resultData) => {
        let dataObject = resultData[1]
        let data = null
        if (Array.isArray(resultData[1])) {
          switch (resultData[0]) {
            case 'address':
              dataObject = dataObject.map(
                (address) =>
                  `${address.city}, ${address.street}, ${address.house}, ${address.flat}`
              )
              break
            case 'phone':
              dataObject = dataObject.map(
                (phone) =>
                  `${phone.number} (дата: ${phone.date}${
                    phone.type ? `, ${this.getPhoneTypeName(phone.type)}` : ''
                  })`
              )
              break
            case 'document':
              dataObject = dataObject.map(
                (document) =>
                  `${document.number} (дата выдачи: ${document.issueDate})`
              )
              break
          }
          if (resultData[0] === 'address') {
            dataObject = dataObject.map(
              (address) =>
                `${address.city}, ${address.street}, ${address.house}, ${address.flat}`
            )
          }
          data = {
            key: resultData[0],
            value: dataObject.join(', '),
          }
          if (Array.isArray(data)) res.push(...data)
          res.push(data)
        } else if (typeof dataObject === 'string') {
          res.push({
            key: resultData[0],
            value: dataObject,
          })
        } else {
          data = Object.entries(dataObject).map((field) => {
            const key = field[0]
            let value = Array.isArray(field[1])
              ? field[1].join(', ')
              : field[1]
            return {
              key: key,
              value: value,
            }
          })
          res.push(...data)
        }
      })
      return res
    },
    getServiceName(value) {
      return SERVICES_CATALOG.find((service) => service.value === value).abbr
    },
    getStatusName(value) {
      return STATUSES_CATALOG.find((status) => status.value === value).text
    },
    getPhoneTypeName(values) {
      return values
          .map((value) => {
            return PHONE_TYPES_CATALOG.find((status) => status.value === value)
                ?.text
          })
          .join(', ')
    },
    downloadReport() {
      const file = this.request.report.find((rep) => rep.result.file).result
          .file
      let link = document.createElement('a')
      link.href = 'data:text/html;charset=utf-8;base64,' + file
      link.download = `Запрос ${123456}`
      document.body.appendChild(link)
      link.click()
      link.remove()
    },
  },
  watch: {
    request: {
      immediate: true,
      handler: 'getData',
    },
  },
}
</script>

<style lang="scss" scoped>
$result-layout-padding: 25px;

.request {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 20px 35px 50px 20px;
    background: #ffffff;
    border-radius: 17px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 45px;
    margin-bottom: 35px;
  }

  &__title {
    color: #141414;
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;

    &-number {
      color: #627ee0;
      font-size: 16px;
      line-height: 23px;
    }
  }

  &__actions {
    &-btn {
      border-radius: 8px;
    }
  }

  &__table {
    width: 800px;
    border: 1px solid #eeeef2 !important;
  }

  &__reports-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &__report {
    &-container {
      padding: 16px 25px 25px 25px;
      background: #f9f9fc;
    }

    &-header {
      margin-bottom: 20px;
    }

    &-info {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    &-title {
      color: #627ee0;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }

    &-status {
      color: #484964;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;

      &--wait {
        color: #0a84f4;
      }

      &--found {
        color: #6eaa6c;
      }

      &--not-found {
        color: #627ee0;
      }

      &--error {
        color: #f27d7d;
      }
    }
  }

  &__result {
    &-container {
      padding: 15px;
      margin-bottom: 40px;
      background: #ffffff;
      border-radius: 8px;
    }

    &-flex-container {
      display: flex;
      flex-wrap: wrap;
      gap: $result-layout-padding;
      margin-bottom: 40px;

      &__item {
        width: calc(50% - #{$result-layout-padding}/ 2);
      }
    }

    &-info {
      display: block;

      &-header {
        display: block;
        margin-bottom: 6px;
        font-size: 18px;
        font-weight: 500;
      }

      &__title {
        font-weight: 500;
      }

      &--neutral {
        color: #0a84f4;
      }

      &--positive {
        color: #6eaa6c;
      }

      &--negative {
        color: #f27d7d;
      }
    }

    &-frame {
      height: 600px;
      width: 100%;
      border-width: 1px;
    }
  }
}

.expansion {
  padding: 0 !important;
  margin-bottom: 20px;
  color: #484964 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  text-transform: none !important;

  &__icon {
    &--active {
      transform: rotate(-180deg);
    }
  }
}

::v-deep .expansion .v-btn__content {
  gap: 10px !important;
  opacity: 1 !important;
}

::v-deep .history__table-first-header {
  width: 200px;
  background: rgba(100, 77, 237, 0.08);
  border-top: 1px solid #627ee0;
}

::v-deep .history__table-first-cell {
  background: rgba(100, 77, 237, 0.08);
}
</style>
