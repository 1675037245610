<template>
  <v-container fluid>
    <RequestData v-if="request" :request="request" :request-number="requestId.split('-')[0]" :key="key" />
    <v-row>
      <RequestHistoryShort />
    </v-row>
  </v-container>
</template>

<script>
import RequestData from '@/views/request/RequestData'
import RequestHistoryShort from '@/components/request/RequestHistoryShort'

export default {
  name: 'Request',
  components: { RequestData, RequestHistoryShort },
  data () {
    return {
      request: null,
      title: '',
      data: [],
      headers: [
        {
          text: 'Поле', value: 'key', sortable: false, class: 'history__table-first-header', cellClass: 'history__table-first-cell', divider: true
        },
        {
          text: 'Значение', value: 'value', sortable: false,
        },
      ],
      dataVisible: false,
      reportVisible: true,
      key: '_'
    }
  },
  computed: {
    requestId () {
      return this.$route.params.id
    }
  },
  methods: {
    async getRequest () {
      await this.axios.get(`/request/${this.$route.params.id}`)
          .then(response => this.request = response.data)
    },
  },
  watch: {
    requestId: {
      immediate: true,
      handler: async function () {
        await this.getRequest()
        this.key += '_'
      }
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
