<template>
  <v-text-field
      outlined
      dense
      v-model="value"
      :label="schema.lbl"
      :hint="hint"
      persistent-hint
      :rules="[...rules, ...schema.rules]"
      append-icon="mdi-pencil-outline"
      :class="{ required: requiredClass }"
  />
</template>

<script>
import { abstractField } from 'vue-form-generator'

export default {
  name: 'TextField',
  mixins: [ abstractField ],
  data () {
    return {
      required: '',
      hint: undefined,
      rules: [],
    }
  },
  computed: {
    requiredClass () {
      return this.schema.required() || !!this.required
    },
  },
  methods: {
    watchDocumentNumber () {
      this.required = !this.model.document?.number
      if (this.required) this.rules = [value => !!value || 'Обязательное поле1']
      else this.rules = []
    },
    watchFIO () {
      this.required = !(this.model.person?.lastName && this.model.person?.firstName)
      if (this.required) this.rules = [value => !!value || 'Обязательное поле']
      else this.rules = []
    },
    watchPersonInn () {
      console.log(this.model)
      this.required = !this.model.person?.inn
      if (this.required) this.rules = [value => !!value || 'Обязательное поле']
      else this.rules = []
    },
  },
  created () {
    if (this.schema.required()) {
      this.rules = [value => (!!value || value === 0) || 'Обязательное поле2']
    } 
    else if (this.schema.dynamicRequired && this.schema.dynamicRequired()) {
  
      this.watchDocumentNumber()
      this.$watch('model.document.number', this.watchDocumentNumber)
      if(this.schema.services){
        console.log(this.schema?.services())
        if(this.schema.services().some((service) => ['bankrot_fl'].includes(service))){
          this.watchFIO()
          this.watchPersonInn()
          this.$watch('model.person.lastName', this.watchFIO)
          this.$watch('model.person.firstName', this.watchFIO)
          this.$watch('model.person.inn', this.watchPersonInn)
        }
      }
      
      
    }
    if (this.schema.default !== undefined) this.value = this.schema.default
    if (this.schema.hnt) this.hint = this.schema.hnt()
  },
}
</script>

<style scoped>

</style>
