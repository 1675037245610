<template>
  <v-menu
      v-model="menu"
      :close-on-content-click="false"
      max-width="290"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          :value="dateFormatted"
          :label="label"
          readonly
          outlined
          v-bind="attrs"
          v-on="on"
          @click:clear="handleClear"
          append-icon="mdi-calendar-outline"
      />
    </template>
    <v-date-picker
        v-model="date"
        :first-day-of-week="1"
        locale="ru-ru"
        @change="handleInput"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { format, parseISO } from 'date-fns'

export default {
  name: 'DatePicker',
  props: {
    value: {
      required: true
    },
    label: {
      type: String,
      required: true,
    }
  },
  data () {
    return {
      date: '',
      menu: false,
    }
  },
  computed: {
    dateFormatted () {
      return this.date ? format(parseISO(this.date), 'dd.MM.yyyy') : ''
    },
  },
  methods: {
    handleInput () {
      this.$emit('input', this.dateFormatted)
      this.menu = false
    },
    handleClear () {
      this.date = null
      this.handleInput()
    },
    watchValue () {
      const data = this.value.split('.')
      let date = new Date(data[2], data[1]-1, data[0]).toISOString()
      this.date = format(parseISO(date), 'yyyy-MM-dd')
    },
  },
  created () {
    if (this.value) {
      this.watchValue()
      this.$watch('value', this.watchValue)
    }
  }
}
</script>

<style scoped>

</style>
