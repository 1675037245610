<template>
  <v-text-field
      outlined
      dense
      v-model="formattedValue"
      label="Паспорт"
      :rules="[...rules, ...schema.rules]"
      v-mask="'#### ######'"
      append-icon="mdi-pencil-outline"
      :class="{ required: requiredClass }"
  />
</template>

<script>
import { abstractField } from 'vue-form-generator'

export default {
  name: 'PassportField',
  mixins: [ abstractField ],
  data () {
    return {
      formattedValue: '',
      required: '',
      rules: [],
    }
  },
  computed: {
    requiredClass () {
      return this.schema.required() || !!this.required
    },
  },
  methods: {
    watchFIO () {
      this.required = !(this.model.person?.lastName && this.model.person?.firstName)
      if (this.required) this.rules = [value => !!value || 'Обязательное поле']
      else this.rules = []
    },
  },
  watch: {
    formattedValue () {
      this.value = this.formattedValue.replace(/\s/g, '')
    },
  },
  created () {
    if (this.schema.required()) {
      this.rules = [value => !!value || 'Обязательное поле']
    } else if (this.schema.dynamicRequired) {
      this.watchFIO()
      this.$watch('model.person.lastName', this.watchFIO)
      this.$watch('model.person.firstName', this.watchFIO)
    }
  },
}
</script>

<style scoped>

</style>
