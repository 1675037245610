import { format, parseISO } from 'date-fns'

export const debounce = function (func, timeout = 200) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => { func.apply(this, args) }, timeout)
  }
}

export const formatDate = (date) => format(parseISO(date), 'dd.MM.yyyy')
