<template>
  <v-container fluid class="container">
    <v-row>
      <RequestCreate @update="getRequests" />
    </v-row>
    <v-row>
      <RequestHistory :key="'rhHome'" :type="'home'" ref="history" />
    </v-row>
  </v-container>
</template>

<script>
import RequestCreate from '@/views/request/RequestCreate'
import RequestHistory from '@/views/request/RequestHistory'

export default {
  name: 'Home',
  components: { RequestHistory, RequestCreate },
  methods: {
    getRequests () {
      this.$refs.history.cursor = {
        limit: 12
      }
      this.$refs.history.getRequests()
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  background-color: #F8F8F8;
}
</style>
