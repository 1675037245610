<template>
  <div>
    <span class="field-group-title-header">
      {{ schema.lbl }}
    </span>
    <span class="field-group-title-hint">
      {{ hint }}
    </span>
  </div>
</template>

<script>
import { abstractField } from 'vue-form-generator'

export default {
  name: 'GroupTitle',
  mixins: [ abstractField ],
  computed: {
    hint () {
      return this.schema.hnt ? this.schema.hnt() : ''
    }
  },
}
</script>

<style lang="scss" scoped>
.field-group-title {
  &-header {
    display: block;
    margin-bottom: 6px;
    font-size: 14px;
    font-weight: 500;
  }

  &-hint {
    display: block;
    color: #717171;
    margin-bottom: 12px;
    font-size: 12px;
  }
}
</style>
