<template>
  <div class="container">
    <v-form v-if="!registered" class="reg__form">
      <span class="reg__heading">Регистрация</span>
      <v-container>
        <v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                  outlined
                  label="Фамилия, имя"
                  :rules="[requiredRule]"
                  v-model="registrationData.profile.responsiblePerson"
                  class="reg__input"
                  append-icon="mdi-pencil-outline" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                  outlined
                  label="Телефон для связи"
                  :rules="[requiredRule, phoneRule]"
                  v-model="registrationData.profile.phone"
                  class="reg__input"
                  append-icon="mdi-phone-outline" />
            </v-col>
            <v-col cols="6">
              <v-text-field
                  outlined
                  label="E-mail"
                  :rules="[requiredRule, emailRule]"
                  v-model="registrationData.email"
                  class="reg__input"
                  append-icon="mdi-email-outline" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                  outlined
                  label="Название организации"
                  :rules="[requiredRule]"
                  v-model="registrationData.profile.businessName"
                  class="reg__input"
                  append-icon="mdi-pencil-outline" />
            </v-col>
            <v-col cols="6">
              <v-text-field
                  outlined
                  label="Вид деятельности"
                  :rules="[requiredRule]"
                  v-model="registrationData.profile.typeOfActivity"
                  class="reg__input"
                  append-icon="mdi-pencil-outline" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                  outlined
                  label="ИНН"
                  :rules="[requiredRule, innRule]"
                  v-model="registrationData.profile.inn"
                  class="reg__input"
                  append-icon="mdi-pencil-outline" />
            </v-col>
            <v-col cols="6">
              <v-text-field
                  outlined
                  label="ОГРН"
                  :rules="[requiredRule, ogrnRule]"
                  v-model="registrationData.profile.ogrn"
                  class="reg__input"
                  append-icon="mdi-pencil-outline" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                  :items="servicesCatalog"
                  outlined
                  multiple
                  small-chips
                  clearable
                  :label="`Необходимые источники ${servicesLength}`"
                  :rules="[requiredRule]"
                  v-model="registrationData.profile.services"
                  class="reg__input"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                  outlined
                  label="Частота запросов в месяц"
                  :rules="[requiredRule]"
                  v-model="registrationData.profile.plannedLoad"
                  class="reg__input"
                  append-icon="mdi-pencil-outline" />
            </v-col>
            <v-col cols="6">
              <v-btn block color="primary" elevation="0" @click="register" class="reg-btn">Зарегистрироваться</v-btn>
            </v-col>
          </v-row>
        </v-row>
      </v-container>
      <v-btn text color="secondary" to="/auth" class="auth-btn">
        <v-icon>mdi-chevron-left</v-icon>
        Авторизоваться
      </v-btn>
    </v-form>
    <RegistrationComplete v-if="registered" />
  </div>
</template>

<script>
import { SERVICES_CATALOG } from '@/helpers/dictionaries'
import RegistrationComplete from '@/components/user/RegistrationComplete'
import {checkINN, checkOgrn} from 'ru-validation-codes'

export default {
  name: 'Registration',
  components: { RegistrationComplete },
  data () {
    return {
      registrationData: {
        email: '',
        profile: {
          responsiblePerson: '',
          phone: '',
          businessName: '',
          typeOfActivity: '',
          inn: '',
          ogrn: '',
          services: '',
          plannedLoad: '',
        },
      },
      servicesCatalog: [],
      registered: false,
      requiredRule: value => !!value || 'Обязательное поле',
      phoneRule: value => /^(8|7)?[3489]\d{9}$/.test(value) || 'Неверный формат',
      emailRule: value =>
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)
        || 'Неверный формат',
      innRule: value => checkINN(value) || 'Неверный формат',
      ogrnRule: value => checkOgrn(value) || 'Неверный формат',
    }
  },
  computed: {
    servicesLength () {
      return this.registrationData.profile.services.length ? `(${this.registrationData.profile.services.length})` : ''
    },
  },
  methods: {
    register () {
      this.$store.dispatch('user/register', this.registrationData)
          .then(() => this.registered = true)
    },
  },
  created () {
    this.servicesCatalog = SERVICES_CATALOG
  },
}
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reg {
  &__form {
    width: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__heading {
    margin-bottom: 40px;
    color: #141414;
    font-size: 28px;
    font-weight: 500;
    line-height: 34px;
  }

  &__input {
    width: 100%;
    border-radius: 8px;
  }

  &-btn {
    height: 56px !important;
  }
}

.auth-btn {
  align-self: start;
}

.col {
  padding: 6px 12px !important;
}
</style>
