<template>
  <div class="container">
    <v-form class="auth__form" @submit.prevent="auth">
      <span class="auth__heading">Вход</span>
      <v-text-field outlined label="Email / Номер договора" v-model="email" :rules="rules" class="auth__input required"/>
      <v-text-field
          type="password"
          outlined
          label="Пароль"
          v-model="password"
          :rules="rules"
          class="auth__input required"/>
      <v-btn block color="primary" type="submit" class="mb-3">Войти</v-btn>
      <v-btn text block color="primary" to="/registration">Регистрация</v-btn>
    </v-form>
  </div>
</template>

<script>
export default {
  name: 'Auth',
  data () {
    return {
      email: '',
      password: '',
      rules: [
        value => !!value || 'Обязательное поле'
      ]
    }
  },
  methods: {
    auth() {
      this.$store.dispatch('user/login', {email: this.email, password: this.password})
          .then(() => this.$router.push({name: 'Home'}))
    },
  }
}
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth {
  &__form {
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__heading {
    margin-bottom: 40px;
    color: #141414;
    font-size: 28px;
    font-weight: 500;
    line-height: 34px;
  }

  &__input {
    width: 100%;
    margin-bottom: 10px;
    border-radius: 8px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}
</style>
