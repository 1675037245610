<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <div class="history-container">
          <span class="history__header--last">Последние запросы по физическим лицам</span>
          <v-text-field
              v-model="search"
              label="Поиск"
              outlined
              dense
              single-line
              hide-details
              append-icon="mdi-magnify"
              class="history__search"
          />
          <v-data-table
              :headers="headers"
              :items="data"
              :custom-sort="customSort"
              :search="search"
              sort-by="date"
              :sort-desc="true"
              locale="ru-RU"
              :loading="pending"
              loading-text=""
              no-data-text="Запросы не найдены"
              hide-default-footer
              @click:row="openRequest"
              class="history__table"
          >
            <template v-slot:item.date="{ item }">
              <span>{{ item.date.formatted }}</span>
            </template>
            <template v-slot:item.sources="{ item }">
              <v-tooltip
                  v-for="source in item.sources"
                  :key="source.title"
                  right
                  :color="
                    source.status === 'wait'
                      ? 'tertiary' : source.status === 'found'
                        ? 'success' : source.status === 'not_found'
                          ? 'primary' : 'error'
                  "
                  class="history__tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <span
                      v-bind="attrs"
                      v-on="on"
                      :class="{
                        'history__status--wait': source.status === 'wait',
                        'history__status--found': source.status === 'found',
                        'history__status--not-found': source.status === 'not_found',
                        'history__status--error': source.status === 'error',
                      }"
                      class="history__status"
                  >
                    #{{ source.abbr }}
                  </span>
                </template>
                <span>{{ source.description }}</span>
              </v-tooltip>
            </template>
            <template v-slot:item.status="{ item }">
              <span
                  :class="{
                    'history__status--wait': item.status.value === 'wait',
                    'history__status--found': item.status.value === 'found',
                    'history__status--partially': item.status.value === 'partially',
                    'history__status--error': item.status.value === 'error'
                  }"
              >
                {{ item.status.text }}</span>
            </template>
          </v-data-table>
        </div>
      </v-col>
    </v-row>
    <v-btn text color="secondary" to="/history">
      <v-icon>mdi-chevron-left</v-icon>
      Все запросы по физическим лицам
    </v-btn>
  </v-container>
</template>

<script>
import { format } from 'date-fns'
import { SERVICES_CATALOG } from '@/helpers/dictionaries'

export default {
  name: 'RequestHistoryShort',
  data () {
    return {
      requests: [],
      data: [],
      headers: [
        {
          text: 'Дата / Время', value: 'date', class: 'history__table-first-header',
          cellClass: ['history__table-first-cell', 'history__table-item'], divider: true
        },
        {
          text: 'Запрос', value: 'title', sortable: false, cellClass: 'history__table-item', divider: true
        },
        {
          text: 'Источники', value: 'sources', sortable: false, cellClass: 'history__table-item', divider: true
        },
        {
          text: 'Статус запроса', value: 'status', sortable: false, cellClass: 'history__table-item',
        },
      ],
      search: '',
      pending: false,
    }
  },
  methods: {
    getRequests () {
      this.pending = true
      this.axios.get('/request?limit=6')
          .then(response => {
            const batch = response.data.edges.map(edge => edge.node)
            this.processData(batch)
          })
      this.pending = false
    },
    processData (batch) {
      this.data = [...batch.map(request => {
        const date = {
          raw: request.createdAt,
          formatted: format(new Date(request.createdAt), 'dd.MM.yyyy HH:mm'),
        }
        const title = this.getRequestTitle(request.params, request.id.split('-')[0])
        const sources = request.report.map(report => {
          return {
            ...SERVICES_CATALOG.find(service => service.value === report.service),
            status: report.status,
          }
        })
        const wait = request.report.find(report => report.status === 'wait')
        const found = request.report.find(report => report.status === 'found')
        const notFound = request.report.find(report => report.status === 'not_found')
        const error = request.report.find(report => report.status === 'error')
        const status = {}
        if (wait && !(found || notFound || error)) {
          status.value = 'wait'
          status.text = 'В обработке'
        } else if ((found || notFound) && !(wait || error)) {
          status.value = 'found'
          status.text = 'Выполнен'
        } else if ((found || notFound) && (wait || error)) {
          status.value = 'partially'
          status.text = 'Выполнен частично'
        } else if (error && !(wait || found || notFound)) {
          status.value = 'error'
          status.text = 'Не выполнен'
        }

        return {
          id: request.id,
          date,
          title,
          sources,
          status,
        }
      })]
    },
    openRequest (item) {
      this.$router.push({ name: 'Request', params: { id: item.id } })
    },
    getRequestTitle (params, requestNumber) {
      let title
      if (params?.person?.lastName || params?.person?.dateOfBirth || params?.document?.number) {
        const fio = `${params.person?.lastName || ''} ${params.person?.firstName || ''} ${params.person?.middleName || ''}`.trim()
        const dateOfBirth = params.person?.dateOfBirth || ''
        const number = params?.document?.number || ''
        title = `Запрос #${requestNumber}: ${fio ? `${fio}` : ''}${dateOfBirth ? `, дата рождения ${dateOfBirth}` : ' '}${number ? `, паспорт ${number}` : ''}`
            .replace(' ,', '')
      } else if (params?.person?.inn) {
        title = `Запрос #${requestNumber}: ИНН ${params.person.inn}`
      } else {
        title = `Запрос #${requestNumber}`
      }
      return title
    },
    customSort (items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] === 'date') {
          if (!isDesc[0]) {
            return new Date(a.date.raw) - new Date(b.date.raw)
          } else {
            return new Date(b.date.raw) - new Date(a.date.raw)
          }
        }
        else if (index[0] === 'status') {
          if (!isDesc[0]) {
            return a[index].text.toLowerCase().localeCompare(b[index].text.toLowerCase())
          }
          else {
            return b[index].text.toLowerCase().localeCompare(a[index].text.toLowerCase())
          }
        } else {
          if(typeof a[index] !== 'undefined'){
            if (!isDesc[0]) {
              return a[index].toLowerCase().localeCompare(b[index].toLowerCase())
            }
            else {
              return b[index].toLowerCase().localeCompare(a[index].toLowerCase())
            }
          }
        }
      })
      return items
    },
  },
  created () {
    this.getRequests()
  },
}
</script>

<style lang="scss" scoped>
.history {
  &-container {
    display: flex;
    flex-direction: column;
  }

  &__header {
    &--last {
      color: #717171;
      font-size: 24px;
      font-weight: 500;
      line-height: 29px;
    }
  }

  &__search {
    width: 300px;
    margin-top: 20px;
  }

  &__table {
    margin: 25px 0 30px 0;
    border: 1px solid #EEEEF2 !important;
  }

  &__status {
    cursor: default;

    &--wait {
      color: #484964;
    }

    &--found {
      color: #6EAA6C;
    }

    &--not-found {
      color: #627EE0;
    }

    &--partially {
      color: #0A84F4;
    }

    &--error {
      color: #F27D7D;
    }
  }
}

::v-deep .history__table-first-header {
  background: rgba(100, 77, 237, 0.08);
  border-top: 1px solid #627EE0;
}

::v-deep .history__table-first-cell {
  background: rgba(100, 77, 237, 0.08);
}

::v-deep .history__table-item {
  cursor: pointer;
}

/*.v-tooltip__content {
  background: #FFFFFF !important;
  border-radius: 12px !important;
  border: 1px solid #6EAA6C;
}*/
</style>
