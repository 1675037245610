import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

const opts = {
  theme: {
    themes: {
      light: {
        primary: '#627EE0',
        secondary: '#0A84F4',
        tertiary: '#484964',
        accent: '#8c9eff',
        success: '#6EAA6C',
        error: '#F27D7D',
      },
    },
  },
}

export default new Vuetify(opts)
