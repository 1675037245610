<template>
  <div>
    <div :class="{ 'fact-match-container--nm': schema.addressType === 'reg' }" class="fact-match-container">
      <span :class="{ 'type-label--solo': schema.addressType !== 'reg' }" class="type-label">{{ typeLabel }}</span>
      <v-checkbox v-if="schema.addressType === 'reg'" v-model="address.matchFact" class="fact-match-checkbox">
        <template v-slot:label>
          <span class="fact-match-checkbox-label">
            Адрес проживания совпадает с адресом регистрации
          </span>
        </template>
      </v-checkbox>
    </div>
    <v-row>
      <v-col cols="7">
        <v-autocomplete
            outlined
            dense
            clearable
            label="Код региона"
            v-model="address.region"
            :items="regionsCatalog"
            item-text="name"
            item-value="code"
            :rules="rules"
            append-icon="mdi-pencil-outline"
            class="required"
        />
      </v-col>
      <v-col cols="5">
        <v-text-field
            outlined
            dense
            v-model="address.city"
            label="Город"
            :rules="rules"
            append-icon="mdi-pencil-outline"
            class="required" />
      </v-col>
      <v-col cols="4">
        <v-text-field outlined dense v-model="address.street" label="Улица" append-icon="mdi-pencil-outline" />
      </v-col>
      <v-col cols="4">
        <v-text-field
            outlined
            dense
            v-model="address.house"
            label="Дом"
            :rules="rules"
            append-icon="mdi-pencil-outline"
            class="required" />
      </v-col>
      <v-col cols="4">
        <v-text-field outlined dense v-model="address.flat" label="Квартира" append-icon="mdi-pencil-outline" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { REGIONS_CATALOG } from '@/helpers/dictionaries'
import { abstractField } from 'vue-form-generator'

export default {
  name: 'AddressFieldGroup',
  mixins: [ abstractField ],
  data () {
    return {
      address: {
        region: '',
        city: '',
        street: '',
        house: '',
        flat: '',
        matchFact: null,
      },
      regionsCatalog: [],
      typeLabel: '',
      rules: [
        value => !!value || 'Обязательное поле'
      ]
    }
  },
  watch: {
    address: {
      deep: true,
      handler: function () {
        this.value = this.address
      },
    },
  },
  created () {
    this.regionsCatalog = REGIONS_CATALOG
    this.schema.addressType === 'reg' ? this.typeLabel = 'Адрес регистрации' : this.typeLabel = 'Адрес проживания'
    if (this.schema.addressType === 'reg') this.address.matchFact = true
  },
}
</script>

<style lang="scss" scoped>
.type-label {
  font-size: 14px;
  font-weight: 500;

  &--solo {
    display: block;
    padding-bottom: 12px;
  }
}

.fact-match {
  &-container {
    display: flex;
    align-items: center;
    gap: 10px;

    &--nm {
      margin-top: -18px;
    }
  }

  &-checkbox {
    &-label {
      font-size: 14px;
    }
  }
}

.row {
  margin: 0 -12px;
}

.col {
  padding: 0 10px !important;
}
</style>
