export const SERVICES_CATALOG = [
  {
    text: 'Предоставление сведений о кредитном рейтинге',
    value: 'pkr',
    abbr: 'ПКР',
    description: 'Персональный кредитный рейтинг',
    visible: true,
  },
  {
    text: 'Обогащение контактных данных',
    value: 'skiptrace',
    abbr: 'СКИП',
    description: 'Обогащение контактных данных',
    visible: true,
  },
  {
    text: 'Получение аналитической справки',
    value: 'expertise',
    abbr: 'АС',
    description: 'Аналитическая справка',
    visible: true,
  },
  {
    text: 'Получение паспортных данных',
    value: 'get_document',
    abbr: 'ПАС',
    description: 'Получение паспортных данных',
    visible: true,
  },
  {
    text: 'Получение адресов проживания/регистрации',
    value: 'get_address',
    abbr: 'АДР',
    description: 'Получение адресов проживания/регистрации',
  },
  {
    text: 'Поиск ИНН',
    value: 'get_inn',
    abbr: 'ИНН',
    description: 'Поиск ИНН',
    visible: true,
  },
  {
    text: 'Налоговая задолженность',
    value: 'nalog_inn',
    abbr: 'НЗ',
    description: 'Налоговая задолженность',
    visible: true,
  },
  {
    text: 'ФССП',
    value: 'fssp_fl',
    abbr: 'ФССП',
    description: 'Федеральная служба судебных приставов',
    visible: true,
  },
  {
    text: 'ФССП',
    value: 'fssp_suspect',
    abbr: 'ФССП_Преступник',
    description: 'Лица, находящиеся в розыске по подозрению в совершении преступлений',
    visible: true,
  },
  {
    text: 'ФССП',
    value: 'fssp_ip_search',
    abbr: 'ФССП_ИП',
    description: 'Реестр розыска по исполнительным производствам',
    visible: true,
  },
  {
    text: 'Реестр залогового движимого имущества',
    value: 'fnp_zalog',
    abbr: 'РЗДИ',
    description: 'Реестр залогового движимого имущества',
  },
  {
    text: 'Единый федеральный реестр сведений о банкротстве',
    value: 'bankrot',
    abbr: 'ЕФРСБ',
    description: 'Получение сведений о банкротстве',
  },
  {
    text: 'Проверка по перечням Росфинмониторинга',
    value: 'fedsfm',
    abbr: 'ППР',
    description: 'Проверка по перечням Росфинмониторинга',
  },
  {
    text: 'Проверка ГИБДД (ВУ, история ДТП, обременение, розыск)',
    value: 'check_auto',
    abbr: 'ГИБДД',
    description: 'Проверка ГИБДД',
  },
  {
    text: 'Проверка по списку недействительных российских паспортов',
    value: 'fms_document',
    abbr: 'ППФМС',
    description: 'Проверка Паспорта РФ',
    visible: true,
  },
  {
    text: 'Проверка лица в базе данных «Розыск»',
    value: 'mvd_wanted',
    abbr: 'РОЗЫСК',
    description: 'МВД Розыск',
    visible: true,
  },
  {
    text: 'Единый федеральный реестр сведений о банкротстве',
    value: 'bankrot_fl',
    abbr: 'БАНКРОТ',
    description: 'Банкротство',
    visible: true,
  },
]

export const STATUSES_CATALOG = [
  {
    text: 'Обработка',
    value: 'wait',
  },
  {
    text: 'Найдено',
    value: 'found',
  },
  {
    text: 'Не найдено',
    value: 'not_found',
  },
  {
    text: 'Ошибка',
    value: 'error',
  },
]

export const PERIODS_CATALOG = [
  {
    text: 'Текущий месяц',
    value: 'currentMonth',
  },
  {
    text: 'Прошлый месяц',
    value: 'lastMonth',
  },
  {
    text: 'Квартал',
    value: 'quarter',
  },
  {
    text: 'Год',
    value: 'year',
  },
  {
    text: 'Пользовательский',
    value: 'custom',
  },
]

export const PHONE_TYPES_CATALOG = [
  {
    text: 'мобильный',
    value: 'mobile',
  },
  {
    text: 'рабочий',
    value: 'work',
  },
  {
    text: 'домашний',
    value: 'home',
  },
  {
    text: 'факс',
    value: 'fax',
  },
]

export const REGIONS_CATALOG = [
  {
    code: '45',
    name: '45 - Москва',
  },
  {
    code: '79',
    name: '79 - Республика Адыгея',
  },
  {
    code: '84',
    name: '84 - Республика Алтай',
  },
  {
    code: '80',
    name: '80 - Республика Башкортостан',
  },
  {
    code: '81',
    name: '81 - Республика Бурятия',
  },
  {
    code: '82',
    name: '82 - Республика Дагестан',
  },
  {
    code: '26',
    name: '26 - Республика Ингушетия',
  },
  {
    code: '83',
    name: '83 - Кабардино-Балкарская Республика',
  },
  {
    code: '85',
    name: '85 - Республика Калмыкия',
  },
  {
    code: '91',
    name: '91 - Карачаево-Черкесская Республика',
  },
  {
    code: '86',
    name: '86 - Республика Карелия',
  },
  {
    code: '87',
    name: '87 - Республика Коми',
  },
  {
    code: '35',
    name: '35 - Крым',
  },
  {
    code: '88',
    name: '88 - Республика Марий Эл',
  },
  {
    code: '89',
    name: '89 - Республика Мордовия',
  },
  {
    code: '98',
    name: '98 - Республика Саха (Якутия)',
  },
  {
    code: '90',
    name: '90 - Республика Северная Осетия - Алания',
  },
  {
    code: '92',
    name: '92 - Республика Татарстан',
  },
  {
    code: '93',
    name: '93 - Республика Тыва',
  },
  {
    code: '94',
    name: '94 - Удмуртская Республика',
  },
  {
    code: '95',
    name: '95 - Республика Хакасия',
  },
  {
    code: '96',
    name: '96 - Чеченская Республика',
  },
  {
    code: '97',
    name: '97 - Чувашская Республика-Чувашия',
  },
  {
    code: '01',
    name: '01 - Алтайский край',
  },
  {
    code: '03',
    name: '03 - Краснодарский край',
  },
  {
    code: '04',
    name: '04 - Красноярский край',
  },
  {
    code: '05',
    name: '05 - Приморский край',
  },
  {
    code: '07',
    name: '07 - Ставропольский край',
  },
  {
    code: '08',
    name: '08 - Хабаровский край',
  },
  {
    code: '76',
    name: '76 - Забайкальский край',
  },
  {
    code: '30',
    name: '30 - Камчатский край',
  },
  {
    code: '10',
    name: '10 - Амурская область',
  },
  {
    code: '11',
    name: '11 - Архангельская область',
  },
  {
    code: '11',
    name: '11 - Ненецкий АО',
  },
  {
    code: '12',
    name: '12 - Астраханская область',
  },
  {
    code: '14',
    name: '14 - Белгородская область',
  },
  {
    code: '15',
    name: '15 - Брянская область',
  },
  {
    code: '17',
    name: '17 - Владимирская область',
  },
  {
    code: '18',
    name: '18 - Волгоградская область',
  },
  {
    code: '19',
    name: '19 - Вологодская область',
  },
  {
    code: '20',
    name: '20 - Воронежская область',
  },
  {
    code: '24',
    name: '24 - Ивановская область',
  },
  {
    code: '25',
    name: '25 - Иркутская область',
  },
  {
    code: '27',
    name: '27 - Калининградская область',
  },
  {
    code: '29',
    name: '29 - Калужская область',
  },
  {
    code: '32',
    name: '32 - Кемеровская область',
  },
  {
    code: '33',
    name: '33 - Кировская область',
  },
  {
    code: '34',
    name: '34 - Костромская область',
  },
  {
    code: '37',
    name: '37 - Курганская область',
  },
  {
    code: '38',
    name: '38 - Курская область',
  },
  {
    code: '41',
    name: '41 - Ленинградская область',
  },
  {
    code: '42',
    name: '42 - Липецкая область',
  },
  {
    code: '44',
    name: '44 - Магаданская область',
  },
  {
    code: '46',
    name: '46 - Московская область',
  },
  {
    code: '47',
    name: '47 - Мурманская область',
  },
  {
    code: '22',
    name: '22 - Нижегородская область',
  },
  {
    code: '49',
    name: '49 - Новгородская область',
  },
  {
    code: '50',
    name: '50 - Новосибирская область',
  },
  {
    code: '52',
    name: '52 - Омская область',
  },
  {
    code: '53',
    name: '53 - Оренбургская область',
  },
  {
    code: '54',
    name: '54 - Орловская область',
  },
  {
    code: '56',
    name: '56 - Пензенская область',
  },
  {
    code: '57',
    name: '57 - Пермский край',
  },
  {
    code: '58',
    name: '58 - Псковская область',
  },
  {
    code: '60',
    name: '60 - Ростовская область',
  },
  {
    code: '61',
    name: '61 - Рязанская область',
  },
  {
    code: '36',
    name: '36 - Самарская область',
  },
  {
    code: '63',
    name: '63 - Саратовская область',
  },
  {
    code: '64',
    name: '64 - Сахалинская область',
  },
  {
    code: '65',
    name: '65 - Свердловская область',
  },
  {
    code: '66',
    name: '66 - Смоленская область',
  },
  {
    code: '68',
    name: '68 - Тамбовская область',
  },
  {
    code: '69',
    name: '69 - Томская область',
  },
  {
    code: '28',
    name: '28 - Тверская область',
  },
  {
    code: '70',
    name: '70 - Тульская область',
  },
  {
    code: '71',
    name: '71 - Тюменская область',
  },
  {
    code: '71',
    name: '71 - Ханты-Мансийский АО — Югра',
  },
  {
    code: '71',
    name: '71 - Ямало-Ненецкий АО',
  },
  {
    code: '73',
    name: '73 - Ульяновская область',
  },
  {
    code: '75',
    name: '75 - Челябинская область',
  },
  {
    code: '78',
    name: '78 - Ярославская область',
  },
  {
    code: '40',
    name: '40 - Санкт-Петербург и ЛО',
  },
  {
    code: '67',
    name: '67 - Севастополь',
  },
  {
    code: '78',
    name: '78 - Ярославская область',
  },
  {
    code: '40',
    name: '40 - Санкт-Петербург и ЛО',
  },
  {
    code: '67',
    name: '67 - Севастополь',
  },
  {
    code: '99',
    name: '99 - Еврейская автономная область',
  },
  {
    code: '77',
    name: '77 - Чукотский АО',
  },
  {
    code: '55',
    name: '"55 - Байконур',
  },
]


export const region = [
  { value: '01', text: 'Республика Адыгея' },
  { value: '02', text: 'Республика Башкортостан' },
  { value: '03', text: 'Республика Бурятия' },
  { value: '04', text: 'Республика Алтай' },
  { value: '05', text: 'Республика Дагестан' },
  { value: '06', text: 'Республика Ингушетия' },
  { value: '07', text: 'Кабардино-Балкарская Республика' },
  { value: '08', text: 'Республика Калмыкия' },
  { value: '09', text: 'Республика Карачаево-Черкессия' },
  { value: '10', text: 'Республика Карелия' },
  { value: '11', text: 'Республика Коми' },
  { value: '12', text: 'Республика Марий Эл' },
  { value: '13', text: 'Республика Мордовия' },
  { value: '14', text: 'Республика Саха (Якутия)' },
  { value: '15', text: 'Республика Северная Осетия-Алания' },
  { value: '16', text: 'Республика Татарстан' },
  { value: '17', text: 'Республика Тува' },
  { value: '18', text: 'Удмуртская Республика' },
  { value: '19', text: 'Республика Хакасия' },
  { value: '21', text: 'Чувашская Республика' },
  { value: '22', text: 'Алтайский край' },
  { value: '23', text: 'Краснодарский край' },
  { value: '24', text: 'Красноярский край' },
  { value: '25', text: 'Приморский край' },
  { value: '26', text: 'Ставропольский край' },
  { value: '27', text: 'Хабаровский край' },
  { value: '28', text: 'Амурская область' },
  { value: '29', text: 'Архангельская область' },
  { value: '30', text: 'Астраханская область' },
  { value: '31', text: 'Белгородская область' },
  { value: '32', text: 'Брянская область' },
  { value: '33', text: 'Владимирская область' },
  { value: '34', text: 'Волгоградская область' },
  { value: '35', text: 'Вологодская область' },
  { value: '36', text: 'Воронежская область' },
  { value: '37', text: 'Ивановская область' },
  { value: '38', text: 'Иркутская область' },
  { value: '39', text: 'Калининградская область' },
  { value: '40', text: 'Калужская область' },
  { value: '41', text: 'Камчатский край' },
  { value: '42', text: 'Кемеровская область' },
  { value: '43', text: 'Кировская область' },
  { value: '44', text: 'Костромская область' },
  { value: '45', text: 'Курганская область' },
  { value: '46', text: 'Курская область' },
  { value: '47', text: 'Ленинградская область' },
  { value: '48', text: 'Липецкая область' },
  { value: '49', text: 'Магаданская область' },
  { value: '50', text: 'Московская область' },
  { value: '51', text: 'Мурманская область' },
  { value: '52', text: 'Нижегородская область' },
  { value: '53', text: 'Новгородская область' },
  { value: '54', text: 'Новосибирская область' },
  { value: '55', text: 'Омская область' },
  { value: '56', text: 'Оренбургская область' },
  { value: '57', text: 'Орловская область' },
  { value: '58', text: 'Пензенская область' },
  { value: '59', text: 'Пермский край' },
  { value: '60', text: 'Псковская область' },
  { value: '61', text: 'Ростовская область' },
  { value: '62', text: 'Рязанская область' },
  { value: '63', text: 'Самарская область' },
  { value: '64', text: 'Саратовская область' },
  { value: '65', text: 'Сахалинская область' },
  { value: '66', text: 'Свердловская область' },
  { value: '67', text: 'Смоленская область' },
  { value: '68', text: 'Тамбовская область' },
  { value: '69', text: 'Тверская область' },
  { value: '70', text: 'Томская область' },
  { value: '71', text: 'Тульская область' },
  { value: '72', text: 'Тюменская область' },
  { value: '73', text: 'Ульяновская область' },
  { value: '74', text: 'Челябинская область' },
  { value: '75', text: 'Забайкальский край' },
  { value: '76', text: 'Ярославская область' },
  { value: '77', text: 'г. Москва' },
  { value: '78', text: 'г. Санкт-Петербург' },
  { value: '79', text: 'Еврейская автономная область' },
  { value: '80', text: 'Агинский Бурятский авт. округ' },
  { value: '81', text: 'Коми-Пермяцкий автономный округ' },
  { value: '82', text: 'Корякский автономный округ' },
  { value: '83', text: 'Ненецкий автономный округ' },
  { value: '84', text: 'Таймырский (Долгано-Ненецкий) автономный округ' },
  { value: '85', text: 'Усть-Ордынский Бурятский автономный округ' },
  { value: '86', text: 'Ханты-мансийский автономный округ' },
  { value: '87', text: 'Чукотский автономный округ' },
  { value: '88', text: 'Эвенкийский автономный округ' },
  { value: '89', text: 'Ямало-Ненецкий автономный округ' },
  { value: '91', text: 'Республика Крым' },
  { value: '92', text: 'Севастополь' },
  { value: '94', text: 'Байконур' },
  { value: '95', text: 'Чеченская Республика' },
  { value: '90', text: 'Другое' },
  { value: '00', text: 'Неизвестно' },
]

export const country = [
  { value: 'AH', text: 'Абхазия' },
  { value: 'AU', text: 'Австралия' },
  { value: 'AT', text: 'Австрия' },
  { value: 'AZ', text: 'Азербайджан' },
  { value: 'AX', text: 'Аландские острова' },
  { value: 'AL', text: 'Албания' },
  { value: 'DZ', text: 'Алжир' },
  { value: 'AS', text: 'Американские Самоа' },
  { value: 'AI', text: 'Ангилья' },
  { value: 'AO', text: 'Ангола' },
  { value: 'AD', text: 'Андорра' },
  { value: 'AQ', text: 'Антарктика' },
  { value: 'AG', text: 'Антигуа и Барбуда' },
  { value: 'AR', text: 'Аргентина' },
  { value: 'AM', text: 'Армения' },
  { value: 'AW', text: 'Аруба' },
  { value: 'SJ', text: 'Архипелаг Шпицберген' },
  { value: 'AF', text: 'Афганистан' },
  { value: 'BS', text: 'Багамы' },
  { value: 'BD', text: 'Бангладеш' },
  { value: 'BB', text: 'Барбадо' },
  { value: 'BH', text: 'Бахрейн' },
  { value: 'BY', text: 'Беларусь' },
  { value: 'BZ', text: 'Белиз' },
  { value: 'BE', text: 'Бельгия ' },
  { value: 'BJ', text: 'Бенин' },
  { value: 'CI', text: 'Берег Слоновой Кости' },
  { value: 'BM', text: 'Бермуды' },
  { value: 'BG', text: 'Болгария' },
  { value: 'BO', text: 'Боливия' },
  { value: 'BA', text: 'Босния и Герцеговина' },
  { value: 'BW', text: 'Ботсвана' },
  { value: 'BR', text: 'Бразилия' },
  { value: 'BN', text: 'Бруней Даруссалам' },
  { value: 'BV', text: 'Буве о-в' },
  { value: 'BF', text: 'Буркина Фасо' },
  { value: 'BI', text: 'Бурунди' },
  { value: 'BT', text: 'Бутан' },
  { value: 'VU', text: 'Вануату' },
  { value: 'VA', text: 'Ватикан' },
  { value: 'GB', text: 'Великобритания' },
  { value: 'HU', text: 'Венгрия' },
  { value: 'VE', text: 'Венесуэла' },
  { value: 'VI', text: 'Виргинские о-ва (США)' },
  { value: 'VG', text: 'Вирджинские о-ва (Великобритания)' },
  { value: 'TP', text: 'Восточный Тимор' },
  { value: 'VN', text: 'Вьетнам' },
  { value: 'GA', text: 'Габон' },
  { value: 'HT', text: 'Гаити' },
  { value: 'GY', text: 'Гайана' },
  { value: 'GM', text: 'Гамбия' },
  { value: 'GH', text: 'Гана' },
  { value: 'GP', text: 'Гваделупа' },
  { value: 'GT', text: 'Гватемала' },
  { value: 'GN', text: 'Гвинея' },
  { value: 'GW', text: 'Гвинея-Бисау' },
  { value: 'DE', text: 'Германия' },
  { value: 'GG', text: 'Гернси' },
  { value: 'GI', text: 'Гибралтар' },
  { value: 'HN', text: 'Гондурас' },
  { value: 'HK', text: 'Гонконг' },
  { value: 'GD', text: 'Гренада' },
  { value: 'GR', text: 'Греция' },
  { value: 'GL', text: 'Гренландия' },
  { value: 'GE', text: 'Грузия' },
  { value: 'GU', text: 'Гуам' },
  { value: 'GY', text: 'Гуана' },
  { value: 'DK', text: 'Дания' },
  { value: 'KP', text: 'Демократическая республика Корея' },
  { value: 'ZR', text: 'Демократическая республика Конго' },
  { value: 'JE', text: 'Джерси' },
  { value: 'DJ', text: 'Джибути' },
  { value: 'DM', text: 'Доминика' },
  { value: 'DO', text: 'Доминиканская республика' },
  { value: 'EG', text: 'Египет' },
  { value: 'ZR', text: 'Заир' },
  { value: 'ZM', text: 'Замбия' },
  { value: 'EH', text: 'Западная Cахара' },
  { value: 'ZW', text: 'Зимбабве' },
  { value: 'IL', text: 'Израиль' },
  { value: 'IN', text: 'Индия' },
  { value: 'ID', text: 'Индонезия' },
  { value: 'JO', text: 'Иордан' },
  { value: 'IQ', text: 'Ирак' },
  { value: 'IR', text: 'Иран' },
  { value: 'IE', text: 'Ирландия' },
  { value: 'IS', text: 'Исландия' },
  { value: 'ES', text: 'Испания' },
  { value: 'IT', text: 'Италия' },
  { value: 'YE', text: 'Йемен' },
  { value: 'CV', text: 'Кабо Верде о-в' },
  { value: 'KZ', text: 'Казахстан' },
  { value: 'KY', text: 'Кайман о-в' },
  { value: 'KH', text: 'Камбоджа' },
  { value: 'CM', text: 'Камерун' },
  { value: 'CA', text: 'Канада' },
  { value: 'QA', text: 'Катар' },
  { value: 'KE', text: 'Кения' },
  { value: 'CY', text: 'Кипр' },
  { value: 'KI', text: 'Кирибати' },
  { value: 'CN', text: 'Китай' },
  { value: 'CC', text: 'Кокосовые о-ва' },
  { value: 'CO', text: 'Колумбия' },
  { value: 'KM', text: 'Коморские о-ва' },
  { value: 'CG', text: 'Конго' },
  { value: 'CR', text: 'Коста-Рика' },
  { value: 'CU', text: 'Куба' },
  { value: 'KW', text: 'Кувейт' },
  { value: 'KG', text: 'Кыргызстан' },
  { value: 'LV', text: 'Латвия' },
  { value: 'LS', text: 'Лесото' },
  { value: 'LR', text: 'Либерия' },
  { value: 'LB', text: 'Ливан' },
  { value: 'LY', text: 'Ливийская Арабская Джимихирия' },
  { value: 'LT', text: 'Литва' },
  { value: 'LI', text: 'Лихтенштейн' },
  { value: 'LU', text: 'Люксембург' },
  { value: 'MU', text: 'Маврикий' },
  { value: 'MR', text: 'Мавритания' },
  { value: 'MG', text: 'Мадагаскар' },
  { value: 'YT', text: 'Майоте' },
  { value: 'MO', text: 'Макао' },
  { value: 'MK', text: 'Македония, бывшая Югославия' },
  { value: 'MW', text: 'Малави' },
  { value: 'MY', text: 'Малайзия' },
  { value: 'ML', text: 'Мали' },
  { value: 'UM', text: 'Малые отдаленные острова США' },
  { value: 'MV', text: 'Мальдивы' },
  { value: 'MT', text: 'Мальта' },
  { value: 'MA', text: 'Марокко' },
  { value: 'MQ', text: 'Мартиник' },
  { value: 'MH', text: 'Маршальские о-ва' },
  { value: 'MX', text: 'Мексика' },
  { value: 'FM', text: 'Микронезия' },
  { value: 'MZ', text: 'Мозамбик' },
  { value: 'MC', text: 'Монако' },
  { value: 'MN', text: 'Монголия' },
  { value: 'MS', text: 'Монсерат' },
  { value: 'MM', text: 'Мъянмар' },
  { value: 'NA', text: 'Намибия' },
  { value: 'LA', text: 'Народная демократическая республика Лаоc' },
  { value: 'NR', text: 'Науру' },
  { value: 'NP', text: 'Непал' },
  { value: 'NE', text: 'Нигер' },
  { value: 'NG', text: 'Нигерия' },
  { value: 'AN', text: 'Нидерландcкие Антильские о-ва' },
  { value: 'NL', text: 'Нидерланды' },
  { value: 'NI', text: 'Никарагуа' },
  { value: 'NU', text: 'Ниуэ' },
  { value: 'NZ', text: 'Новая Зеландия' },
  { value: 'NC', text: 'Новая Каледония' },
  { value: 'NO', text: 'Норвегия' },
  { value: 'NF', text: 'Норфолк о-в' },
  { value: 'AE', text: 'ОАЭ' },
  { value: 'CM', text: 'Объединенная республика Камерун' },
  { value: 'TZ', text: 'Объединенная республика Танзания' },
  { value: 'CK', text: 'О-ва Кука' },
  { value: 'WF', text: 'О-ва Уоллес и Футуна' },
  { value: 'HM', text: 'О-ва Херд и Макдональд ' },
  { value: 'OM', text: 'Оман' },
  { value: 'PK', text: 'Пакистан' },
  { value: 'PW', text: 'Палау' },
  { value: 'PS', text: 'Палестинская территория окупированная' },
  { value: 'PA', text: 'Панама' },
  { value: 'PG', text: 'Папуа Новая Гвинея' },
  { value: 'PY', text: 'Парагвай' },
  { value: 'PE', text: 'Перу' },
  { value: 'PN', text: 'Питкэрн' },
  { value: 'PL', text: 'Польша' },
  { value: 'PT', text: 'Португалия' },
  { value: 'PR', text: 'Пуэрто-Рико' },
  { value: 'KR', text: 'Республика Корея' },
  { value: 'MD', text: 'Республика Молдавия' },
  { value: 'RE', text: 'Реюньон о-в ' },
  { value: 'CX', text: 'Рождественский о-в' },
  { value: 'RU', text: 'Российская Федерация' },
  { value: 'RW', text: 'Руанда' },
  { value: 'RO', text: 'Румыния' },
  { value: 'WS', text: 'Самоа' },
  { value: 'SM', text: 'Сан-Марино' },
  { value: 'ST', text: 'Сан-Томе и Принсипи' },
  { value: 'SA', text: 'Саудовская Аравия' },
  { value: 'SH', text: 'Св. Елена' },
  { value: 'SZ', text: 'Свазиленд' },
  { value: 'MP', text: 'Северные Мариинские о-ва' },
  { value: 'SC', text: 'Сейшелы' },
  { value: 'SN', text: 'Сенегал' },
  { value: 'BL', text: 'Сент-Бертелеми' },
  { value: 'VC', text: 'Сент-Винсент и Гренадинские о-ва' },
  { value: 'LC', text: 'Сент-Люсия' },
  { value: 'PM', text: 'Сент-Пьер и Микелон' },
  { value: 'KN', text: 'Сент-Китис и Невис' },
  { value: 'MF', text: 'Сент-Мартин' },
  { value: 'RS', text: 'Сербия ' },
  { value: 'SG', text: 'Сингапур' },
  { value: 'SY', text: 'Сирийская арабская республика' },
  { value: 'SK', text: 'Словакия' },
  { value: 'SI', text: 'Словения' },
  { value: 'SB', text: 'Соломона о-в' },
  { value: 'SO', text: 'Сомали' },
  { value: 'SD', text: 'Судан' },
  { value: 'SR', text: 'Суринам' },
  { value: 'US', text: 'США' },
  { value: 'SL', text: 'Сьерра Леоне' },
  { value: 'TJ', text: 'Таджикистан' },
  { value: 'TH', text: 'Таиланд' },
  { value: 'TW', text: 'Тайвань, провинция Китая' },
  { value: 'IO', text: 'Территория Великобритании в Индийском океане' },
  { value: 'TL', text: 'Тимор Лесте' },
  { value: 'TG', text: 'Того' },
  { value: 'TK', text: 'Токелау' },
  { value: 'TO', text: 'Тонга' },
  { value: 'TT', text: 'Тринидад и Тобаго' },
  { value: 'TV', text: 'Тувалу' },
  { value: 'TN', text: 'Тунис' },
  { value: 'TC', text: 'Турецкие о-ва и Кайка' },
  { value: 'TM', text: 'Туркменистан' },
  { value: 'TR', text: 'Турция' },
  { value: 'UG', text: 'Уганда' },
  { value: 'UZ', text: 'Узбекистан' },
  { value: 'UA', text: 'Украина' },
  { value: 'UY', text: 'Уругвай' },
  { value: 'FO', text: 'Фарерские острова' },
  { value: 'FJ', text: 'Фиджи' },
  { value: 'PH', text: 'Филиппины' },
  { value: 'FI', text: 'Финляндия' },
  { value: 'FK', text: 'Фольклендские о-ва (Мальвинские)' },
  { value: 'FR', text: 'Франция' },
  { value: 'GF', text: 'Французская Гвинея' },
  { value: 'PF', text: 'Французская Полинезия' },
  { value: 'TF', text: 'Французская южная территория' },
  { value: 'HR', text: 'Хорватия' },
  { value: 'CF', text: 'Центральная африканская республика' },
  { value: 'TD', text: 'Чад' },
  { value: 'CZ', text: 'Чехия' },
  { value: 'ME', text: 'Черногория' },
  { value: 'CL', text: 'Чили' },
  { value: 'CH', text: 'Швейцария' },
  { value: 'SE', text: 'Швеция' },
  { value: 'LK', text: 'Шри Ланка' },
  { value: 'EC', text: 'Эквадор' },
  { value: 'GQ', text: 'Экваториальная Гвинея' },
  { value: 'SV', text: 'Эль Сальвадор' },
  { value: 'ER', text: 'Эритрея' },
  { value: 'EE', text: 'Эстония' },
  { value: 'ET', text: 'Эфиопия' },
  { value: 'YU', text: 'Югославия' },
  { value: 'ZA', text: 'Южная Африка' },
  { value: 'JS', text: 'Южная Джорджия и Южные Сандвичевы острова' },
  { value: 'JM', text: 'Ямайка' },
  { value: 'JP', text: 'Япония' },
  { value: '99', text: 'Неизвестн' },
]

