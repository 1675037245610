import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import VueFormGenerator from 'vue-form-generator/dist/vfg-core.js'
import VueTheMask from 'vue-the-mask'
import VueI18n from 'vue-i18n'
import VueScrollTo from 'vue-scrollto'
import vuetify from '@/plugins/vuetify'

import router from '@/routes'
import { store } from '@/store/store'
import { axiosInstance } from '@/plugins/axios'
import { messages } from '@/i18n/ru/messages'
import App from './App.vue'

import AddressFieldGroup from './components/form/vfg/fieldAddressGroup'
import DateField from './components/form/vfg/fieldDate'
import GroupTitle from './components/form/vfg/fieldGroupTitle'
import PassportField from './components/form/vfg/fieldPassport'
import PhoneField from './components/form/vfg/fieldPhone'
import RegionField from './components/form/vfg/fieldRegion'
import TextField from './components/form/vfg/fieldText'
import CheckBox from './components/form/vfg/fieldCheckBox'
Vue.component('field-address-group', AddressFieldGroup)
Vue.component('field-date', DateField)
Vue.component('field-group-title', GroupTitle)
Vue.component('field-passport', PassportField)
Vue.component('field-phone', PhoneField)
Vue.component('field-region', RegionField)
Vue.component('field-text', TextField)
Vue.component('field-check-box', CheckBox)

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(VueFormGenerator)
Vue.use(VueTheMask)
Vue.use(VueI18n)
Vue.use(VueScrollTo, {
  offset: -50,
})

Vue.prototype.axios = axiosInstance

const i18n = new VueI18n({
  locale: 'ru',
  messages: messages
})

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app')
