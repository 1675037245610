<template>
  <div>
    <v-data-table
        :items="data"
        :headers="headers"
        :items-per-page="-1"
        hide-default-footer
        class="request__table"
    >
      <template v-slot:item.body="{item}"> <v-btn text @click="downloader(item.body, item.id)">Скачать</v-btn>   </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'FnpZalogVisualization',
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          key: 'Номер залога',
          value: 'id',
        },
        {
          key: 'Дата регистрации',
          value: 'regDate',
        },
        {
          key: 'Скачать',
          value: 'body',
        },
      ]
    }
  },
  methods: {
    downloader(body, name){
      let blob = new Blob([Buffer.from(body, 'base64')], {type: 'application/pdf'})
      let url = window.URL.createObjectURL(blob)
      let link = document.createElement('a')
      link.download = `${name}.pdf`
      link.href = url
      link.click()
      window.URL.revokeObjectURL(url)
    }
  }
}
</script>

