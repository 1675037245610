<template>
  <div class="sources-container">
    <div class="sources-header">
      <span class="sources-header__step">
        1
      </span>
      <h4 class="sources-header__heading">Выберите источники</h4>
    </div>
    <div v-for="service in servicesCatalog" :key="service.value" class="switch-container">
      <v-switch dense inset v-model="services" :value="service.value" class="v-input--reverse v-input--expand">
        <template v-slot:label>
          <div class="switch__info">
            <span class="switch__info-title">
              <!-- {{ service.abbr }} -->
              {{ service.description }}
            </span>
            <!-- <span class="switch__info-desc">
              {{ count(service.value) }}
            </span> -->
          </div>
        </template>
      </v-switch>
    </div>
  </div>
</template>

<script>
import { SERVICES_CATALOG } from '@/helpers/dictionaries'

export default {
  name: 'RequestServices',
  data () {
    return {
      services: [],
      servicesCatalog: [],
      serverInfo: []
    }
  },
  methods: {
    reset () {
      this.services = []
    },
    count(serviceCode){
      return this.serverInfo.find(el=>el.code===serviceCode).count
    }
  },
  watch: {
    services: {
      deep: true,
      handler: function () {
        this.$emit('input', this.services)
      }
    },
  },
  async created () {
    const {data: user} = await this.axios.get('/session/user')
    this.serverInfo = user.service
    this.servicesCatalog = SERVICES_CATALOG.filter(s => {
      return !(user.service.find(el=>el.code==s.value)?.disabled ?? true)
    })
  },
}
</script>

<style lang="scss" scoped>
.sources-container {
  min-height: 700px;
  background-color: #FFF;
  border-radius: 17px;
}

.sources-header {
  display: flex;
  align-items: center;
  gap: 10px;

  padding: 30px;
  margin-bottom: 5px;

  &__step {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background: #627EE0;
    color: #FFFFFF;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    border-radius: 50%;
  }

  &__heading {
    color: #627EE0;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  }
}

.switch {
  &-container {
    padding: 0px 20px 0px 20px;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  &__info {
    display: flex;
    gap: 5px;

    font-size: 0.8em;
    font-weight: 400;
    line-height: 15px;

    &-title {
      color: #141414;
    }

    &-desc {
      color: #717171;
    }
  }

  &__action {
    display: flex;
    gap: 15px;
    align-items: center;

    &-details {
      height: 30px;
      width: 30px;
      color: #FFFFFF;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 30px;
      background: #627EE0;
      border-radius: 8px;
    }
  }
}

// Reversed input variant
::v-deep .v-input--reverse .v-input__slot {
  width: 100%;
  flex-direction: row-reverse;
  justify-content: flex-end;
  .v-application--is-ltr & {
    .v-input--selection-controls__input {
      margin-right: 0;
      margin-left: 8px;
    }
  }
  .v-application--is-rtl & {
    .v-input--selection-controls__input {
      margin-left: 0;
      margin-right: 8px;
    }
  }
}

// Bonus "expand" variant
::v-deep .v-input--expand .v-input__slot {
  // justify-content: space-between;
  .v-label {
    display: block;
    flex: 1;
  }
}
</style>
