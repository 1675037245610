<template>
  <div class="container">
    <span class="heading">Отлично!</span>
    <p class="text">
      Ваша заявка направлена менеджеру.<br>
      Мы свяжемся с Вами в ближайшее время!
    </p>
    <v-btn text color="secondary" to="/auth">
      <v-icon>mdi-chevron-left</v-icon>
      Авторизоваться
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'RegistrationComplete'
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heading {
  margin-bottom: 85px;
  color: #627EE0;
  font-size: 48px;
  font-weight: 500;
  line-height: 59px;
}

.text {
  color: #141414;
  font-size: 28px;
  font-weight: 500;
  line-height: 34px;
  text-align: center;
  margin-bottom: 115px;
}
</style>
