import { axiosInstance as axios } from '@/plugins/axios'

export const user = {
  state: {
    email: '',
    id: '',
    token: localStorage.getItem('token') || '',
    exp: '',
    role: ''
  },
  getters: {
    isLoggedIn: state => {
      return !!state.token
    },
    tokenExpiration: state => {
      return state.exp
    },
    userName: state => {
      return state.email
    },
    role: () => {
      const token = localStorage.getItem('token')
      if(!token) return ''
      const parsedToken = JSON.parse(atob(token.split('.')[1]))
      
      return parsedToken.role
    },
  },
  mutations: {
    setUser: (state, payload) => {
      state.email = payload.email
      state.id = payload.id
    },
    setToken: (state, payload) => {
      state.token = payload.token
      state.exp = payload.exp
    },
    restoreAuth: (state) => {
      const parsedToken = JSON.parse(atob(state.token.split('.')[1]))
      state.exp = parsedToken.exp
      state.email = parsedToken.email
      state.id = parsedToken.id
    },
  },
  actions: {
    async login ({ commit }, payload) {
      await axios.post('session/auth', {
        email: payload.email,
        password: payload.password
      }).then(response => {
        const token = response.data.token
        const parsedToken = JSON.parse(atob(token.split('.')[1]))
        console.log(parsedToken)
        localStorage.setItem('token', token)
        localStorage.setItem('exp', parsedToken.exp)
        localStorage.setItem('email', parsedToken.email)
        localStorage.setItem('id', parsedToken.id)
        localStorage.setItem('role', parsedToken.role)
        commit('setToken', { token, exp: parsedToken.exp })
        commit('setUser', { email: parsedToken.email, id: parsedToken.id, role:  parsedToken.role})
       
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      })
    },
    async logout ({ commit }) {
      localStorage.removeItem('token')
      commit('setToken', { token: '', exp: '' })
      commit('setUser', { email: '', id: '' })
      axios.defaults.headers.common['Authorization'] = ''
    },
    async register (payload) {
      await axios.post('session/registration', payload)
    },
  },
  namespaced: true,
}
