<template>
  <v-container fluid>
    <v-row>
      <v-col cols="4">
        <RequestServices v-model="services" ref="services" />
      </v-col>
      <v-col cols="8">
        <div class="request-container">
          <div class="form__header">
            <span class="form__step">
              2
            </span>
            <h3 class="form__heading">Заполните параметры запроса</h3>
          </div>
          <v-form ref="form">
            <v-container>
              <v-row>
                <vue-form-generator tag="div" :schema="schema" :model="request" />
              </v-row>
            </v-container>
          </v-form>
          <v-btn v-if="services.length" color="primary" depressed @click="openDialog" class="form__btn">Отправить запрос</v-btn>
          <div v-else class="no-source">
            <v-icon class="no-source__icon">
              mdi-alert-outline
            </v-icon>
            <span>Выберите источники!</span>
          </div>
        </div>
      </v-col>
    </v-row>
    <RequestAgreement :dialog="agreementDialog" @submit="submitRequest" @close="agreementDialog = false" />
  </v-container>
</template>

<script>
import { formConfig } from '@/views/request/formConfig'
import RequestServices from '@/views/request/RequestServices'
import RequestAgreement from '@/components/request/RequestAgreement'

export default {
  name: 'RequestCreate',
  components: { RequestAgreement, RequestServices },
  data () {
    return {
      services: [],
      request: {
        options: {},
      },
      agreementDialog: false,
      schema: {}
    }
  },
  methods: {
    async sendRequest () {
      if (this.$refs.form.validate()) {
        await this.axios.post('request', this.prepareRequestData())
        this.$emit('update')
      }
    },
    prepareRequestData () {
      const payload = {}
      payload.services = this.services
      payload.typeRequest = 'site'
      payload.fz152 = 1
      payload.params = { ...this.request }
      if (payload.params.addressReg) {
        payload.params.addressReg.type = 1
        if (payload.params.addressFact) {
          payload.params.addressFact.type = 2
        } else {
          payload.params.addressFact = {...payload.params.addressReg}
          payload.params.addressFact.type = 2
        }
        payload.params.address = [payload.params.addressReg, payload.params.addressFact]
        delete payload.params.addressReg
        delete payload.params.addressFact
      }
      if (payload.services.includes('get_document')) {
        if (payload.params?.person?.lastName && payload.params?.person?.firstName && !payload.params?.document?.number) {
          payload.params.test = 'person'
        } else if (payload.params?.document?.number && !(payload.params?.person?.lastName && payload.params?.person?.firstName)) {
          payload.params.test = 'document'
        }
      }
      if (payload.services.includes('get_address')) {
        if (payload.params?.person?.lastName && payload.params?.person?.firstName && !payload.params?.document?.number) {
          payload.params.test = 'person'
        } else if (payload.params?.document?.number && !(payload.params?.person?.lastName && payload.params?.person?.firstName)) {
          payload.params.test = 'document'
        }
      }
      return payload
    },
    openDialog () {
      if (this.$refs.form.validate()) {
        this.agreementDialog = true
      }
    },
    async submitRequest () {
      this.agreementDialog = false
      await this.sendRequest()
      this.reset()
    },
    reset () {
      this.$refs.services.reset()
      this.services = []
      this.request = {
        options: {},
      }
    },
  },
  created(){
    this.schema = formConfig(this)
  }
}
</script>

<style lang="scss" scoped>
.request-container {
  min-height: 700px;
  height: fit-content;
  padding: 30px;
  background-color: #FFF;
  border-radius: 17px;
}

.form {
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    gap: 10px;
  }

  &__step {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background: #627EE0;
    color: #FFFFFF;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    border-radius: 50%;
  }

  &__heading {
    color: #627EE0;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  }

  &__btn {
    border-radius: 8px;
  }
}

.no-source {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;

  height: calc(100% - 110px);
  width: 100%;

  &__icon {
    font-size: 65px;
  }
}

.vue-form-generator {
  width: 100%;
}
</style>
