export const messages = {
  ru: {
    field: {
      fio: 'ФИО',
      lastName: 'Фамилия',
      firstName: 'Имя',
      middleName: 'Отчество',
      dateOfBirth: 'Дата рождения',
      placeOfBirth: 'Место рождения',
      phone: 'Телефон',
      address: 'Адрес',
      document: 'Паспорт',
      number: 'Паспорт',
      issueDate: 'Дата выдачи',
      issueAuthority: 'Орган выдачи',
      issueCountry: 'Место выдачи',
      inn: 'ИНН',
      limit: 'Лимит',
      months: 'Актуальность (мес.)',
      lastUpdate: 'Последнее обновление',
      name: 'Должник',
      ip_end: 'Дата, причина окончания или прекращения ИП',
      bailiff: 'Судебный пристав исполнитель',
      actualDate: 'Дата актуальности',
      details: 'Реквизиты исполнительного документа',
      subject: 'Предмет исполнения, сумма непогашенной задолженности',
      department: 'Отдел судебных приставов',
      exe_production: 'Исполнительное производство',
      date: 'Дата',
      info: 'Информация',
      ip: 'Исполнительное производство (№, дата)',
      contact: 'Наименование ОСП, где осуществляется розыск, адрес',
      caseNumber: 'Розыскное дело (№, дата)',
      wantedObject: 'Объект розыска',
      debtor: 'Должник',
      suspect: 'Подозреваемый',
      wanted: 'Розыск',
      criminalCode: 'Статья УК РФ',
      region: 'Регион',
      legal: {
        number: 'Номер судебного дела'
      },
      place_of_residence: 'Адрес проживания',
      inn_and_snisl: 'ИНН и СНИЛС',
      arbitrManagerFio: 'Арбитражный управляющий'
    }
  }
}
