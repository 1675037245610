<template>
  <v-navigation-drawer app clipped class="sidebar-container">
    <div class="sidebar">
      <div class="sidebar__item sidebar__item--active">
        <v-icon class="sidebar__item-icon">mdi-account-outline</v-icon>
        Физ. лица
      </div>
      <div class="sidebar__item sidebar__item--disabled">
        <v-icon class="sidebar__item-icon">mdi-domain</v-icon>
        Юр. лица
      </div>
      <div class="sidebar__item sidebar__item--disabled">
        <v-icon class="sidebar__item-icon">mdi-car-outline</v-icon>
        ТС
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'Sidebar'
}
</script>

<style lang="scss" scoped>
.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;

  padding: 20px 25px;
  height: 100%;

  &-container {
    width: 200px !important;
    max-width: 200px !important;
    background: #F8F8F8 !important;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    height: 100px;
    width: 100px;
    color: #717171;
    font-size: 16px;
    line-height: 20px;
    border-radius: 20px;

    &--active {
      background: #627EE0;
      color: #FFFFFF;
    }

    &--disabled {
      cursor: not-allowed;
      background: #E0E0E0;
      color: #717171;
    }

    &--active .sidebar__item-icon {
      color: #FFFFFF !important;
    }

    &-icon {
      font-size: 34px;
    }
  }
}
</style>
