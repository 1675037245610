<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        width="950"
    >
      <div class="agreement-container">
        <h2 class="agreement__heading">Запрос к источникам</h2>
        <p class="agreement__text">
          Пользователь обязуется соблюдать конфиденциальность персональных данных и обеспечивать безопасность при их
          обработке, хранении и передаче в соответствии со 152-ФЗ «О персональных данных».
        </p>
        <v-checkbox v-model="agreement" class="agreement__checkbox">
          <template v-slot:label>
            <span class="agreement__checkbox-label">
              Я принимаю условия
            </span>
          </template>
        </v-checkbox>
        <v-btn :disabled="!agreement" color="primary" depressed @click="submit" class="mb-5">Выполнить запрос</v-btn>
        <v-btn text color="secondary" @click="closeDialog">
          <v-icon>mdi-chevron-left</v-icon>
          Отменить запрос
        </v-btn>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'RequestAgreement',
  props: {
    dialog: {
      type: Boolean,
      required: true,
    }
  },
  data () {
    return {
      agreement: false,
    }
  },
  methods: {
    submit () {
      this.$emit('submit')
      this.agreement = false
    },
    closeDialog () {
      this.$emit('close')
      this.agreement = false
    },
  }
}
</script>

<style lang="scss" scoped>
.agreement {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 50px 60px;
    background: #FFFFFF;
    border-radius: 8px;
  }

  &__heading {
    margin-bottom: 50px;
    color: #141414;
    font-size: 28px;
    font-weight: 500;
    line-height: 34px;
  }

  &__text {
    color: #141414;
    font-size: 16px;
    line-height: 23px;
  }

  &__checkbox {
    align-self: start;
    margin-bottom: 50px;

    &-label {
      color: #000000;
      font-size: 16px;
      line-height: 23px;
    }
  }
}
</style>
