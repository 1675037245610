<template>
  <v-app-bar dense app clipped-left elevation="0" class="header">
    <v-toolbar-title class="header-title">
      <v-icon class="header-title__icon">mdi-account-circle-outline</v-icon>
      {{ userName }}
    </v-toolbar-title>
    <v-spacer/>
    <v-toolbar-items class="header-nav">
      <v-btn text to="/" active-class="header-nav__item--active" class="header-nav__item">Создать запрос</v-btn>
      <v-btn text to="/history" active-class="header-nav__item--active" class="header-nav__item">История запросов</v-btn>
      <v-btn text to="/statistics" active-class="header-nav__item--active" class="header-nav__item">Статистика</v-btn>
      <v-btn text @click="logout" class="header-nav__item header-nav__exit">
        Выйти <v-icon class="ml-1">mdi-logout</v-icon>
      </v-btn>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Header',
  computed: {
    ...mapGetters('user', ['userName'])
  },
  methods: {
    logout () {
      this.$store.dispatch('user/logout')
          .then(() => this.$router.push({ name: 'Auth' }))
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  background-color: #F8F8F8 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;

  &-title {
    display: flex;
    align-items: center;
    gap: 15px;

    font-size: 18px;
    line-height: 22px;

    &__icon {
      font-size: 28px !important;
    }
  }

  &-nav {
    display: flex;
    align-items: center;
    gap: 35px;

    &__item {
      color: #141414 !important;
      font-size: 18px !important;
      line-height: 22px;
      letter-spacing: 0;
      text-transform: none !important;
      text-decoration: none;

      &--active {
        color: #627EE0 !important;
        border-top: 4px solid #627EE0;

        &:before {
          background-color: #F8F8F8 !important;
        }
      }
    }

    &__exit {
      color: #717171 !important;
      font-size: 16px !important;
      line-height: 20px !important;
    }
  }
}
</style>
